import './musicsWindow.css'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { openGlobalWindowAction } from '../../store/windowReduser'
import WindowTitleComponent from '../windowTitleComponent/windowTitleComponent'
import NewItem from '../newItem/newItem'
import CopyrightComponent from '../copyrightComponent/copyrightComponent'

function MusicsWindow(props){
    const dispatch = useDispatch()
    const openMain = () =>{
        dispatch(openGlobalWindowAction(1))
      }
    
    const musics = useSelector(state=>state.musics.musics)


    //анімація
    const animationQueueList = [0,1,2,3,4,5,6]
    const [animationQueue, setAnimationQueue] = useState(0)
    async function startAnimation(type){
        switch (type) {
            case 'open':
                for (let i = 0; i < animationQueueList.length; i++) {
                    const item = animationQueueList[i];
                    await new Promise((resolve) => setTimeout(resolve, 50)); // Затримка на 50мс
                    setAnimationQueue(item);
                }
                break;
            case 'close':
                for (let i = animationQueueList.length - 1; i >= 0; i--) {
                    const item = animationQueueList[i];
                    await new Promise((resolve) => setTimeout(resolve, 50)); // Затримка на 50мс
                    setAnimationQueue(item);
                }
                break;
        
            default:
                break;
        }
    }

    useEffect(()=>{
        //запуск анімації
        startAnimation('open')
    },[])

    return(
        <>
            <div className='window'>
                <div showfrom={animationQueue<1?'bottom':''} elstatus={animationQueue<1?'hide':'show'}>
                    <WindowTitleComponent vars={{title:'Галерея'}} />   
                </div>
                <article showfrom={animationQueue<2?'bottom':''} elstatus={animationQueue<2?'hide':'show'} id='newsWindow'>
                    {musics.length<=0? (
                        <section className='newsNull'>
                            <p>Тут поки пусто ;(</p>
                            <button onClick={openMain}>На головну</button>
                        </section>
                    )
                    :
                    (
                        <>
                            {musics.map((item, idx)=> (<NewItem key={idx} vars={{newItem:item}}/>))}
                        </>
                    )}
                </article>
            </div>
        <CopyrightComponent vars={{color:'black'}}/>
        </>
    )
}

export default MusicsWindow