//?qrCode=0 -> посилання на створення qrCode
//?feedback=0 -> посилання на відправлення відгуку
//?rentShelves=0 -> посилання на відправлення запити на оренду стелажа
//?openCategoryItem='news'&itemId=6 -> посилання на виведення конкретних обєктів


//css and react
import './App.css';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//store
import { setLoadingOffAction } from './store/windowReduser';
//components
import NavigationMenu from './components/navigationMenu/navigationMenu';
import HeaderMenu from './components/headerMenu/headerMenu';
import MainWindow from './components/mainWindow/mainWindow';
import BasketWindow from './components/basketWindow/basketWindow';
import AboutWindow from './components/aboutWindow/aboutWindow';
import NewsWindow from './components/newsWindow/newsWindow';
import СontackWindow from './components/contactWindow/contackWindow'
import MenuWindow from './components/menuWindow/menuWindow';
import Loading from './components/loadingWindow/loading';
import FeedbackWindow from './components/feedbackWindow/feedbackWindow';
import QrCodeWindow from './components/qrCodeWindow/qrCodeWindow';
import RentShelves from './components/rentShelves/rentShelves';
import ActionsWindow from './components/actionsWindow/actionsWindow';
import MusicsWindow from './components/musicsWindow/musicsWindow';
import OpenCategoryItemWindow from './components/openCategoryItemWindow/openCategoryItemWindow';
import CreateCryptoBill from './components/createCryptoBill/createCryptoBill';
import UpdateMenuWindow from './components/updateMenuWindow/updateMenuWIndow';
import GalaryItemWindow from './components/galaryItemWindow/GalaryItemWindow';
//servises
import { get_menu } from './servises/menuServises';
import { getNews, getTopNews } from './servises/newsServises';
import { getActions } from './servises/actionsServises';
import { getMusics } from './servises/musicServises';
import { checkUserUuid } from './servises/uuidServise';
import { set_orders } from './servises/orederServises';
//redux
import { set_basket_from_storage_action } from './store/menuReduser';

import { useLocation } from "react-router-dom";

function App() {
  // створює діспач
  const dispatch = useDispatch()
  // відповідає за відображення кнопки вверх
  const [showButton, setShowButton] = useState(false);
  // перевірка чи користувач включив навігаційне меню
  const [navMenuActive, setNavMenuActive] = useState(false)

  const location = useLocation();
  // Отримати шлях з локації
  const path = location.pathname;

  // ф-ція яка включає/виключає екран загрузки
  const loadingStatus = useSelector(state => state.window.loadingStatus)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(()=>{
    async function sync(){
      checkUserUuid()
      dispatch(set_basket_from_storage_action())

      if(path==='/'){
        // отримує списко новин на головному екрані(слайди)
        await getTopNews(dispatch)
      }
      if(path==='/basket/'){
        await set_orders(dispatch)
      }
      if(path==='/about/'){}
      if(path==='/news/'){
        // отримує список новин
        await getNews(dispatch)
      }
      if(path==='/contact/'){}
      if(path==='/menu/'){
        await get_menu(dispatch)
      }
      if(path==='/feedback/'){}
      if(path==='/qrcode/'){}
      if(path==='/rentshelves/'){}
      if(path==='/actions/'){
        // отримує список подій
        await getActions(dispatch)
      }
      if(path==='/galary/'){
        // отримує список музики
        await getMusics(dispatch)
      }
      if(path==='/opencategoryitemwindow/'){}
      if(path==='/createCryptoBill/'){}
      if(path==='/updateMenu/'){}
      if(path==='/galaryItem/'){
        console.log('item');
      }

      dispatch(setLoadingOffAction())
    }
    sync()
  },[])
  
// ф-ція що горає торінку вверх
  const scrollUp = () => {
    document.querySelector(`.App`).scrollTo(0, 0)
  }
  useEffect(() => {
    console.log(path)
    const handleScroll = () => {
      const scrollTop = document.querySelector('.App').scrollTop;
      if (scrollTop > 140) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    document.querySelector(".App").addEventListener("scroll", handleScroll);
    return () => {
      document.querySelector(".App").removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //funcs
  const getUrlParameter = (title) => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramData = queryParams.get(title);
    return paramData ? paramData : null
  }
  

  return (
    <div className="App">
      {loadingStatus?<Loading/>:(
        <div className="app-wrapper">
          {navMenuActive && <NavigationMenu funcs={{closeNavMenu:setNavMenuActive}}/>}
          <HeaderMenu funcs={{openNavMenu:setNavMenuActive}}/>
          {path==='/' && 
            <MainWindow/>
          }
          {path==='/basket/' && 
            <BasketWindow/>
          }
          {path==='/about/' && 
            <AboutWindow/>
          }
          {path==='/news/' && 
            <NewsWindow/>
          }
          {path==='/contact/' && 
            <СontackWindow/>
          }
          {path==='/menu/' && 
            <MenuWindow/>
          }
          {path==='/feedback/' && 
            <FeedbackWindow/>
          }
          {path==='/qrcode/' && 
            <QrCodeWindow/>
          }
          {path==='/rentshelves/' && 
            <RentShelves/>
          }
          {path==='/actions/' && 
            <ActionsWindow/>
          }
          {path==='/galary/' && 
            <MusicsWindow/>
          }
          {path==='/opencategoryitemwindow/' && 
            <OpenCategoryItemWindow/>
          }
          {path==='/createCryptoBill/' && 
            <CreateCryptoBill/>
          }
          {path==='/updateMenu/' && 
            <UpdateMenuWindow/>
          }
          {path==='/galaryItem/' && 
            <GalaryItemWindow item_id={getUrlParameter('item')}/>
          }
          <button style={{ display: showButton ? "block" : "none" }} onClick={() => scrollUp()} className='up-button'><i className="material-icons">expand_less</i></button>
        </div>
      )}
    </div>
  );
}

export default App;
