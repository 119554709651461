const defaultState = {
    //прописуєтсья сторінка, яка зараз відкрита
    globalWindowOpen: 1,
    //
    menuCategoryId: 0,
    //прописуються сторінки які є в додатку
    globalWindowData:{
        1:{
            title: 'Main',
            openInsideWindow: 0,
            insideWindowData:{
                1:{
                    title:''
                }
            }
        },
        2:{
            title: 'Кошик',
            openInsideWindow: 0,
            insideWindowData:{
                1:{
                    title:''
                }
            }
        },
        3:{
            title: 'Про нас',
            openInsideWindow: 0,
            insideWindowData:{
                1:{
                    title:''
                }
            }
        },
        4:{
            title: 'Новини',
            openInsideWindow: 0,
            insideWindowData:{
                1:{
                    title:''
                }
            }
        },
        5:{
            title: 'Контакти',
            openInsideWindow: 0,
            insideWindowData:{
                1:{
                    title:''
                }
            }
        },
        6:{
            title: 'Меню',
            openInsideWindow: 0,
            insideWindowData:{
                1:{
                    title:'Сніданки'
                },
                2:{
                    title:'Десерти'
                }
            }
        },
        10:{
            title: 'Події',
            openInsideWindow: 0,
            insideWindowData:{
            }
        },
        11:{
            title: 'Музика',
            openInsideWindow: 0,
            insideWindowData:{
            }
        }
    },
    //показує чи вібражаєтсья еркан загрузки чи ні
    loadingStatus: true
}

//сторінки
const OPEN_GLOBAL_WINDOW = 'OPEN_GLOBAL_WINDOW'
const OPEN_INSIDE_WINDOW = 'OPEN_INSIDE_WINDOW'
const SET_MENU_CATEGORY = 'SET_MENU_CATEGORY'
//загрузка
const SET_LOADING_OFF = 'SET_LOADING_OFF'

export const windowReduser = (state = defaultState, action) => {
    switch (action.type) {
        //сторінки
        case OPEN_GLOBAL_WINDOW:
            localStorage.setItem('globalWindowOpen', action.payload);
            return {...state, globalWindowOpen: action.payload}
        case OPEN_INSIDE_WINDOW:
            return {...state, globalWindowData:
                 {...state.globalWindowData, [state.globalWindowOpen]:
                    {...state.globalWindowData[state.globalWindowOpen],openInsideWindow:action.payload}
                }
            }
        case SET_MENU_CATEGORY:
            return {...state, menuCategoryId: action.payload}
        //загрузка
        case SET_LOADING_OFF:
            return {...state, loadingStatus: false}
    
        default:
            return state
    }
}

//сторінки
export const openGlobalWindowAction = (payload) => ({type:OPEN_GLOBAL_WINDOW, payload})
export const openInsideWindowAction = (payload) => ({type:OPEN_INSIDE_WINDOW, payload})
export const setMenuCateforyAction = (payload=false) => ({type:SET_MENU_CATEGORY, payload})
//загрузка
export const setLoadingOffAction = (payload=false) => ({type:SET_LOADING_OFF, payload})