import './feedbackWindow.css'
import { useState } from 'react';
import RatingComponent from '../ratingComponent/ratingComponent';
import ModalWindow from '../modalWindow/modalWindow';
import { sendFeedback } from '../../servises/feedbackServises';

function FeedbackWindow(props){
    const { funcs, vars } = props;

    const [name, setName] = useState('');
    const [phoneMask, setPhoneMask] = useState('')
    const [description, setDescription] = useState('');
    const [rating, setRating] = useState(5);

    const [phoneValidClass, setPhoneValidClass] = useState('')
    const codes = ['39','50','63','66','67','68','91','92','93','94','95','96','97','98','99']


    const [alertModal, setAlertModal] = useState(null)

    const closeModalAndReaload = () => {
      localStorage.setItem('globalWindowOpen',1)
      setAlertModal(null)
      window.location.href = '/'
    }
  
    async function handleSubmit(event){
      event.preventDefault();
      if(phoneValidClass==='false' ){
          setAlertModal(<ModalWindow vars={{type:'alert', title:'Введіть вірний номер телефону!',choices:[{title:'Добре', value:true}] }} funcs={{closeModal:()=>{setAlertModal(null)}}}/>)
          return
      }
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phoneMask!==''?'+380'+phoneMask:'');
      formData.append('desctiprion', description);
      formData.append('rate', rating);

      const sendResult = await sendFeedback(formData)

      sendResult?
          setAlertModal(<ModalWindow vars={{type:'alert', title:'Дякуємо за відгук!',choices:[{title:'Добре', active: true, value:true}] }} funcs={{closeModal: closeModalAndReaload}}/>)
          :
          setAlertModal(<ModalWindow vars={{type:'alert', title:'Щось пішло не по плану :(',choices:[{title:'Добре', value:true}] }} funcs={{closeModal:()=>{setAlertModal(null)}}}/>)
    };

    const changePhone = (num) => {
      let mask = num.replace(/[^+\d]/g, '')
      // mask = mask.replace('', '')
      mask = (mask[0]+mask[1])==='38'?mask.replace('38', ''):mask
      mask = mask.split('').map((char,idx)=>{
          switch (idx) {
              case 0:
                  return '('+char
              case 3:
                  return ')'+char
              case 6:
                  return '-'+char
              case 8:
                  return '-'+char
          
              default:
                  return char
          }
      })

      mask = mask.join('')
      console.log( codes.indexOf((mask[2]+mask[3])) === -1);
      if(mask==='')setPhoneValidClass('')
      else if(mask[1]!='0' || codes.indexOf((mask[2]+mask[3])) === -1)setPhoneValidClass('false')
      else if(mask.split('').length<14)setPhoneValidClass('false')
      else setPhoneValidClass('true')

      setPhoneMask(mask)
  }
    
  
    return (
      <article id="feedbackWindow">
        
        {alertModal!==null&&alertModal}

        <h1>Поділіться своїми враженнями!</h1>
        <form onSubmit={handleSubmit}>
        <input required placeholder="Ім'я" type="text" value={name} onChange={(event) => setName(event.target.value)} />
        <input className={`f-0 ${phoneValidClass}`} value={phoneMask} onChange={(e)=>{changePhone(e.target.value)}} type='text' maxLength='14' placeholder='(097) 000-00-00'></input>
        <textarea placeholder='Додайте опис' value={description} onChange={(event) => setDescription(event.target.value)} />
        
        <RatingComponent/>
        <button type="submit">Відправити</button>
        </form>
      </article>
    );
}

export default FeedbackWindow