
const defaultState = {
    //усі новини
    actions:[]
}

//викачування подій із сервера
const UPDATE_ACTIONS = 'UPDATE_ACTIONS'

export const actionsReducer = (state = defaultState, action) => {
    switch (action.type) {
        //викачування подій із сервера
        case UPDATE_ACTIONS:
            return {...state, actions: action.payload}
        default:
            return state
    }
}

//викачування подій із сервера
export const updateActionsAction = (payload) => ({type:UPDATE_ACTIONS, payload})