import { calculate_basket_sum, calculate_limited_goods_sum } from "./menuServises"
import { set_order_action } from "../store/menuReduser"


export const create_payment_for_order = async(props) => {
    const {type, goods} = props
    const user_uuid = localStorage.getItem("user_uuid")
    
    const formData = new FormData();
    formData.append('type', type);
    formData.append('goods', JSON.stringify(goods));
    formData.append('sum', calculate_basket_sum(goods));
    formData.append('limited_sum', calculate_limited_goods_sum(goods));
    formData.append('user_uuid', user_uuid);

    try {
        const response = await fetch('/api/menu/createPaymentForOrder/', {
            method: 'POST',
            body: formData
        });
        const jsonAnswer = await response.json();
        // console.log('Відповідь від сервера:', jsonAnswer);
        return jsonAnswer
    } catch (error) {
        // console.log('Помилка при отриманні відповіді від сервера:', error);
        return false
    }
}

export const create_new_order_in_poster = async(props) => {
    const { goods } = props
    const user_uuid = localStorage.getItem("user_uuid")
    
    const formData = new FormData();
    formData.append('goods', JSON.stringify(goods));
    formData.append('sum', calculate_basket_sum(goods));
    formData.append('limited_sum', calculate_limited_goods_sum(goods));
    formData.append('user_uuid', user_uuid);

    try {
        const response = await fetch('/api/menu/createNewOrderInPoster/', {
            method: 'POST',
            body: formData
        });
        const jsonAnswer = await response.json();
        // console.log('Відповідь від сервера:', jsonAnswer);
        return jsonAnswer
    } catch (error) {
        // console.log('Помилка при отриманні відповіді від сервера:', error);
        return false
    }
}

export const get_orders = async() => {
    try {
        const user_uuid = localStorage.getItem("user_uuid")

        const response = await fetch(`/api/menu/getOrders/?user_uuid=${user_uuid}`);
        const jsonAnswer = await response.json();
        // console.log('Відповідь від сервера:', jsonAnswer);
        if (jsonAnswer.result) {
            return jsonAnswer
        } else {
            return false
        }
    } catch (error) {
        // console.log('Помилка при отриманні відповіді від сервера:', error);
        return false
    }
}

export const delete_order = async(order) => {
    try {
        const response = await fetch(`/api/menu/deleteOrder/?orderId=${order.id}`);
        const jsonAnswer = await response.json();
        // console.log('Відповідь від сервера:', jsonAnswer);
        if (jsonAnswer.result) {
            return jsonAnswer
        } else {
            return false
        }
    } catch (error) {
        // console.log('Помилка при отриманні відповіді від сервера:', error);
        return false
    }

}

export const set_orders = async(dispatch) => {
    const orders = await get_orders()
    if(orders.result != false){
        dispatch( set_order_action(orders.data) )
    }
}