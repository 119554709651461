const defaultState = {
    menu: [],
    basket: [],
    orders: [],
    menu_categories_way: [],
    menu_categories_last_point: undefined
}

const SET_MENU = 'SET_MENU'
const SET_CATEGORIES_WAY = 'SET_CATEGORIES_WAY'

const ADD_BASKET_ITEM = 'ADD_BASKET_ITEM'
const ADD_ORDER_ITEM = 'ADD_ORDER_ITEM'

const REMOVE_BASKET_ITEM = 'REMOVE_BASKET_ITEM'
const CLEAR_BASKET = 'CLEAR_BASKET'

const INCREASE_BASKET_ITEM = 'INCREASE_BASKET_ITEM'
const REDUCE_BASKET_ITEM = 'REDUCE_BASKET_ITEM'

const SET_BASKET_FROM_LOCALSTORAGE = 'SET_BASKET_FROM_LOCALSTORAGE'
const SET_ORDERS = 'SET_ORDERS'

const LOAD_BASKET_FROM_LOCAL_STORAGE = 'LOAD_BASKET_FROM_LOCAL_STORAGE'



export const menuReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_MENU:
            return {...state, menu: action.payload}

        case SET_CATEGORIES_WAY:
            return {...state, menu_categories_way: action.payload.way, menu_categories_last_point:action.payload.last_point}

        case ADD_BASKET_ITEM:
            const newAddBasket = [...state.basket, action.payload]
            localStorage.setItem('basket', JSON.stringify(newAddBasket))
            return {
                ...state,
                basket: newAddBasket
            }

        case REMOVE_BASKET_ITEM:
            const { product_id, custom_good_id } = action.payload;
            
            let newRemoveBasket = [];
            if (custom_good_id) {
                newRemoveBasket = state.basket.filter(item =>
                item.product_id !== product_id || item.custom_good_id !== custom_good_id
                );
            } else {
                newRemoveBasket = state.basket.filter(item => item.product_id !== product_id);
            }
            
            localStorage.setItem('basket', JSON.stringify(newRemoveBasket));
            return {
                ...state,
                basket: newRemoveBasket
            };
              
        case INCREASE_BASKET_ITEM:
            const increasedBasket = state.basket.map(item => {
                if (item.product_id === action.payload.product_id && item.selected_modificator_id === action.payload.selected_modificator_id) {
                    return {
                        ...item,
                        count: item.count + 1
                    }
                }
                return item;
            });
            localStorage.setItem('basket', JSON.stringify(increasedBasket));
            return {
                ...state,
                basket: increasedBasket,
            };
            
        case REDUCE_BASKET_ITEM:
            const reducedBasket = state.basket.map(item => {
                if (item.product_id === action.payload.product_id && item.custom_good_id === action.payload.custom_good_id) {
                    return {
                        ...item,
                        count: item.count - 1
                    }
                }
                return item;
            }).filter(item => item.count > 0);
            localStorage.setItem('basket', JSON.stringify(reducedBasket));
            return {
                ...state,
                basket: reducedBasket,
            };

        case ADD_ORDER_ITEM:
            return {...state, menu: action.payload}

        case SET_BASKET_FROM_LOCALSTORAGE:
            // localStorage.setItem('basket',JSON.stringify([]));
            const savedBasket = JSON.parse(localStorage.getItem('basket'));
            return {
                ...state,
                basket: savedBasket || []
            }
        case SET_ORDERS:
            return{
                ...state, 
                orders: action.payload
            }

        case CLEAR_BASKET:
            console.log('clear basket');
            localStorage.setItem('basket',JSON.stringify([]));
            const clearedBasket = JSON.parse(localStorage.getItem('basket'));
            return {
                ...state,
                basket: clearedBasket || []
            }
 
        case LOAD_BASKET_FROM_LOCAL_STORAGE:
            const storedBasket = localStorage.getItem('baskset')
            if (storedBasket) {
                const parsedBasket = JSON.parse(storedBasket)
                return {
                    ...state,
                    basket: parsedBasket
                }
            }
            return state
    
        default:
            return state
    }
}

export const set_menu_action = (payload) => ({type:SET_MENU, payload})
export const set_categories_way_action = (payload) => ({type:SET_CATEGORIES_WAY, payload})

export const add_basket_item_action = (payload) => ({type:ADD_BASKET_ITEM, payload})
export const add_order_item_action = (payload) => ({type:ADD_ORDER_ITEM, payload})

export const remove_basket_item_action = (payload) => ({type:REMOVE_BASKET_ITEM, payload})
export const clear_basket_action = (payload) => ({type:CLEAR_BASKET, payload})

export const increase_basket_item_action = (payload) => ({type:INCREASE_BASKET_ITEM, payload})
export const reduce_basket_item_action = (payload) => ({type:REDUCE_BASKET_ITEM, payload})

export const set_basket_from_storage_action = (payload) => ({type:SET_BASKET_FROM_LOCALSTORAGE, payload})
export const set_order_action = (payload) => ({type:SET_ORDERS, payload})