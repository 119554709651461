import { useState } from 'react'
import './ratingComponent.css'

function RatingComponent(props){
    const[rate, setRate] = useState(5)

    return(
        <section className='ratingComponent'>
            {[...Array(5)].map((_,idx)=><button type='button' onClick={()=>{setRate(idx)}}><span className={`material-icons`}>{idx>rate?'star_border':'star'}</span></button>)}
        </section>
    )
}

export default RatingComponent