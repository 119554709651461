
const defaultState = {
    //усі новини
    galary:[
        {
            qef:'wefewfw',
        },
        {
            qef:'wefewfw',
        },
        {
            qef:'wefewfw',
        },
        {
            qef:'wefewfw',
        }
    ]
}

//викачування подій із сервера
const UPDATE_GALARY = 'UPDATE_GALARY'

export const galaryReducer = (state = defaultState, action) => {
    switch (action.type) {
        //викачування подій із сервера
        case UPDATE_GALARY:
            return {...state, galary: action.payload}
        default:
            return state
    }
}

//викачування подій із сервера
export const updateGalaryAction = (payload) => ({type:UPDATE_GALARY, payload})