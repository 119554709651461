export async function sendRent(type, formData){
    formData.append('type', type);

    const response = await fetch('/api/support/addRent/', {
        method: 'POST',
        body: formData,
      });
    const responceJson = await response.json();
    return responceJson
}


export async function getRentedData(type, id){
//вказується тип (стелає чи полотно)
//вказується айді після чого отримується з сервера дані про резерв і виводяться зарзервовані дати
let response = null
let responceJson = null
switch (type) {
    case 'canvas':
        response = await fetch(`api/support/getReserved/?canvas_id=${id}`);
        responceJson = await response.json();
        return responceJson.reserved_dates;

    case 'shelves':
        response = await fetch(`api/support/getReserved/?shelves_id=${id}`);
        responceJson = await response.json();
        return responceJson.reserved_dates;

    default:
        break;
}
}