import './copyrightComponent.css'

function CopyrightComponent(props){
    const{vars,funcs} = props
    const{color} = vars

    return(
        <p style={{color:color?color:'black'}} className='copyrightComponent f-0'>Copyright @ 2023 Інформатика</p>
    )
}

export default CopyrightComponent