import { useRef } from 'react'

import './newGalary.css'

//components
import Doters from '../doters/doters'

function NewGalary(props){

    const galleryRef = useRef(null)
    // console.log(galleryRef);

    //Виводимо інфу
    return(
        <>
            <section className='galary-slider' ref={galleryRef}>
                {props.children}
            </section>
            {galleryRef.current !== null && (
                 <Doters viewType='scroll' scrollViewElem={galleryRef}/>
            )}
        </>
    )
}

export default NewGalary