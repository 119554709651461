import './openCategoryItemWindow.css'
import { useSelector } from 'react-redux'
import NewItem from '../newItem/newItem'
import CopyrightComponent from '../copyrightComponent/copyrightComponent'

function OpenCategoryItemWindow(props){
    const searchParams = new URLSearchParams(window.location.search);
    const category = searchParams.get("openCategoryItem");
    const itemId = searchParams.get("itemId");

    const allCategoryesItems = {
        news: useSelector(state => state.news.news),
        actions: useSelector(state => state.actions.actions),
        musics: useSelector(state => state.musics.musics)
    }

    let items = []
    switch (category) {
        case 'news':
            items = allCategoryesItems.news
            break;
        case 'actions':
            items = allCategoryesItems.actions
            break;
        case 'musics':
            items = allCategoryesItems.musics
            break;
        default:
            break;
    }

    const item = items.find(item => item.id === itemId)
    
    return(
        <>
            <div className='window'>
                <article id='newsWindow'>
                    {item? <NewItem vars={{newItem:item}}/>:<></>}
                </article>
            </div>
            <CopyrightComponent vars={{color:'black'}}/>
        </>
    )
}

export default OpenCategoryItemWindow