import './style.css'
//react
import { useEffect, useState } from 'react';
//images
import imagel from '../../images/loader.gif'
//servises
import { update_menu_on_server } from '../../servises/menuServises';

function UpdateMenuWindow(props){
    const [isMenuUpdated, setIsMenuUpdated] = useState(-1)
    
    useEffect(()=>{
        const start_sinc = async() => {
            const update_menu_result = await update_menu_on_server()
            if (update_menu_result){
                setIsMenuUpdated(true)
            }else setIsMenuUpdated(false)
        }
        start_sinc()
    },[])

    return(
        <article id='simple-loading'>
            {isMenuUpdated === -1 && (<img src={imagel} alt="Example GIF"></img>)}
            {isMenuUpdated === true && (<i className="fa fa-check" style={{color:'white', fontSize:'20vw'}}></i>)}
            {isMenuUpdated === false && (<i className="fa fa-close" style={{color:'red', fontSize:'20vw'}}></i>)}
        </article>
    )
}

export default UpdateMenuWindow