import './style.css'
//components
import Doters from '../doters/doters'
import GoodItem from '../goodItem/goodItem'
import { useSelector } from 'react-redux'
import { useRef, useEffect, useState } from 'react'

function GoodItemList(props){
    //props
    const {viewType, good} = props
    //state
    const [update, setUpdate] = useState(false)
    //consts
    const basket = useSelector(state => state.menu.basket)
    // console.log(basket);
    //ref
    const scrollEnd = useRef(true)
    const ElemRef = useRef(null)
    //funcs
    const is_good_in_basket = () => {
        return basket.some(item => item.product_id === good.product_id);
    }
    const get_good_from_basket = () => {
        return basket.find(item => item.product_id === good.product_id)
    }
    const get_same_id_good_in_basket = () => {
        return basket.filter(item => item.product_id === good.product_id);
    }
    const get_good_modificators = () => {
        return good.modifications ? good.modifications : [];
    }
    const get_group_good_modificators = () => {
        return good.group_modifications ? good.group_modifications : [];
    }
    const updateList = () => {
        setUpdate(!update)
    }
    function scroll_to_element(id) {
        const element = document.querySelector(`section[id="${id}"]`);
        if (element) {
          const container = ElemRef.current;
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const scrollLeft = elementRect.left - containerRect.left + container.scrollLeft;
          const containerWidth = container.clientWidth;
          const elementWidth = elementRect.width;
          container.scrollLeft = scrollLeft - (containerWidth - elementWidth) / 2;
        }
    }
    function scroll_to_end() {
        const container = ElemRef.current;
        if (container) {
            container.scrollLeft = container.scrollWidth;
        }
    }
    useEffect(() => {
        scroll_to_end();
    }, [update]);

    return(
        <>
            <section className='good-items-list' ref={ElemRef}>
                {viewType==='menu' && (
                    <>
                        {get_good_modificators().length > 0 && (
                            <>
                                <GoodItem viewType='menu' good={good} scrollToItem={scroll_to_element} updateList={updateList}/>
                                {get_same_id_good_in_basket().map((item, idx) => <GoodItem key={idx} viewType='basket' showType='menu' good={{...item}}/>)}
                            </>
                        )}

                        {get_group_good_modificators().length > 0 && (
                            <>
                                <GoodItem viewType='menu' good={good} scrollToItem={scroll_to_element} updateList={updateList}/>
                                {get_same_id_good_in_basket().map((item, idx) => <GoodItem key={idx} viewType='basket' showType='menu' good={{...item}}/>)}
                            </>
                        )}

                        {get_good_modificators().length <= 0 && get_group_good_modificators().length <= 0 && (
                            <>
                                {is_good_in_basket()?(
                                    <GoodItem viewType='basket' showType='menu' good={{...get_good_from_basket()}}/>
                                ):
                                (
                                    <GoodItem viewType='menu' good={{...good}}/>
                                )}
                            </>
                        )}
                    </>
                )}
                {viewType==='basket' && (
                    <>
                        {get_good_modificators().length > 0 && (
                            <>
                                {/* <GoodItem viewType='menu' good={good} scrollToItem={scroll_to_element} updateList={updateList}/> */}
                                {get_same_id_good_in_basket().map((item, idx) => <GoodItem key={idx} viewType='basket' showType='basket' good={{...item}}/>)}
                            </>
                        )}

                        {get_group_good_modificators().length > 0 && (
                            <>
                                {get_same_id_good_in_basket().map((item, idx) => <GoodItem key={idx} viewType='basket' showType='basket' good={{...item}}/>)}
                            </>
                        )}

                        {get_good_modificators().length <= 0 && get_group_good_modificators().length <= 0 && (
                            <>
                                {is_good_in_basket()?(
                                    <GoodItem viewType='basket' showType='basket' good={{...get_good_from_basket()}}/>
                                ):
                                (
                                    <GoodItem viewType='menu' good={{...good}}/>
                                )}
                            </>
                        )}
                    </>
                )}
            </section>
            <Doters viewType='scroll' scrollViewElem={ElemRef}/>
        </>
    )
}

export default GoodItemList