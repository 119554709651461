const generateUniqueId = () => {
    const uuid = require('uuid'); // Підключаємо бібліотеку uuid
  
    return uuid.v4(); // Генеруємо і повертаємо унікальний ідентифікатор
  }
export const checkUserUuid = () => {
    if (!localStorage.getItem("user_uuid")) {
        const userUuid = generateUniqueId(); // Генеруємо новий унікальний ідентифікатор
        localStorage.setItem("user_uuid", userUuid); // Зберігаємо його в localStorage під ключем "user_uuid"
    }else{
        // console.log('-> USER UUID:');
        // console.log(localStorage.getItem("user_uuid"));
    }
}