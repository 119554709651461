//icons
import good_icon from '../../images/good_icon.png'
import food_icon from '../../images/food_icon.png'
import drinks_icon from '../../images/drink_icon.png'
//css
import './menuWindow.css'
//components
import GoodItem from '../goodItem/goodItem'
import GoodItemList from '../goodItemsList/goodItemsList'
import FooterMenu from '../footerMenu/footerMenu'
//react
import { useEffect,useState, useRef } from 'react';
//redux
import { useDispatch, useSelector } from 'react-redux'
//servises
import { update_menu } from '../../servises/menuServises';
import { startAnimation } from '../../servises/animationServises'
//route
import { useLocation } from "react-router-dom";

function MenuWindow(props){
    const dispatch = useDispatch()
    //location
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    //useSelectors
    const menu = useSelector(state => state.menu.menu)
    const menu_categories_way = useSelector(state => state.menu.menu_categories_way)
    const menu_categories_last_point = useSelector(state => state.menu.menu_categories_last_point)

    // console.log(menu_categories_last_point);
    //useStates
    const [animationQueue, setAnimationQueue] = useState(0)
    //useRefs
    const galleryCategoriesRef = useRef(null)
    const galaryWidth = useRef(0);
    const itemWidth = useRef(0)

    //funcs
    const select_category = (categories, category) => {
        categories.forEach(category => {
            category.is_select = false
        });
        category.is_select = true
        update_menu(menu, dispatch)
    }
    const set_select_category_from_get_parameters = () => {
        select_category(menu_categories_way[0],menu_categories_way[0][category])
    }
    //useEffects
    useEffect(()=>{
        startAnimation({
            type: 'open',
            animationQueueList: [0, 1, 2, 3, 4, 5, 6],
            setAnimationQueue,
          });
    },[])

    useEffect(() => {
        if (galleryCategoriesRef.current) {
            galaryWidth.current = galleryCategoriesRef.current.scrollWidth;
            itemWidth.current = galleryCategoriesRef.current.clientWidth;
        }
    }, []);
    
    useEffect(() => {
        const sync = async() => {
            set_select_category_from_get_parameters()
        }
        sync()
    },[])

    return(
        <>
            <div className='window'>
                <article id='menuLists' showfrom={animationQueue<1?'top':''} elstatus={animationQueue<1?'hide':'show'}>
                    {menu_categories_way.map((categories, idx) => (
                        <section key={idx}>
                            {categories.length>0 && (
                                <>
                                {idx===0?(
                                    <>
                                        <section className='menuIconList'>
                                            {categories.map((category, idx) => (
                                                <button key={idx} onClick={()=>{select_category(categories, category)}} className={category.is_select?'select':''}>
                                                    {category.icon_title==='food_icon' && (
                                                        <img src={food_icon}></img>
                                                    )}
                                                    {category.icon_title==='good_icon' && (
                                                        <img src={good_icon}></img>
                                                    )}
                                                    {category.icon_title==='drink_icon' && (
                                                        <img src={drinks_icon}></img>
                                                    )}
                                                </button>
                                            ))}
                                        </section>
                                    </>
                                ):(
                                    <>
                                    <section className='menuList'>
                                        {categories.map((category, idx) => (
                                             <button key={idx} onClick={()=>{select_category(categories, category)}} className={`f-1 ${category.parent_category==='0'?category.icon_title:''} ${category.is_select?'select':''}`}>{category.category_name}</button>
                                        ))}
                                    </section>

                                    <div className='doters'>
                                        {categories.map((category, idx) => (
                                           <span key={idx} className={`${category.is_select?'fill':''}`}></span>
                                        ))} 
                                    </div>
                                </>
                                )}
                                </>
                                
                            )}
                        </section>
                    ))}
                </article>
                <article id='menuWindow' showfrom={animationQueue<2?'bottom':''} elstatus={animationQueue<2?'hide':'show'} >
                        {menu_categories_last_point.length<=0?
                            <p className='f-1'>Скоро буде доступно ;)</p>
                        :
                            menu_categories_last_point.map(good => <GoodItemList key={good.product_id} viewType='menu' good={good}/>)
                        }
                </article>
            </div>
            <FooterMenu/>
        </>
    )
}

export default MenuWindow