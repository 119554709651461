import './style.css'
import mapImage from '../../images/map.jpeg'
import { useSelector } from 'react-redux'
import { Fragment } from 'react'
//components
import CopyrightComponent from '../copyrightComponent/copyrightComponent'
import NewGalary from '../newGalary/newGalary'
import NewGalaryItem from '../newGalaryItem/newGalaryItem'
//servises
import { generateContent } from '../../servises/galaryServises'

function GalaryItemWindow(props){
    const {item_id} = props

    const galary = useSelector(state=>state.galary.galary)
    const item = galary[parseInt(item_id)]['qef']
    
    const description = 'text: уалзоцу уцабу ауца цуа; image: image.png; list: image.jpeg, image2.jpeg;';

    const content = generateContent(description);

    console.log(content);

    return(
        <>

        <div className='window'>

        </div>
        <CopyrightComponent vars={{color:'black'}}/>
        </>
    )
}

export default GalaryItemWindow