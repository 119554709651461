import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { globalStore } from './store/globalStore';
import { BrowserRouter as Router} from 'react-router-dom';

import './index.css';
import './fonts.css'

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={globalStore}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
