//react
import React, { useEffect, useState, useRef, useCallback } from 'react'
//style
import './basketWindow.css'
//components
import OrdersItemsList from '../ordersItemsList/orderItemsList'
import CopyrightComponent from '../copyrightComponent/copyrightComponent'
import WindowTitleComponent from '../windowTitleComponent/windowTitleComponent'
import ModalWindow from '../modalWindow/modalWindow'
import GoodItemList from '../goodItemsList/goodItemsList'
import SimpleLoadingWindow from '../simpleLoadingWindow/simpleLoadingWindow'
//redux
import { useDispatch,useSelector } from 'react-redux'
//servises
import { has_limited_goods, calculate_basket_sum, calculate_limited_goods_sum } from '../../servises/menuServises'
import { create_payment_for_order, set_orders, create_new_order_in_poster, delete_order } from '../../servises/orederServises'
import { startAnimation } from '../../servises/animationServises'

//redux
import { clear_basket_action } from '../../store/menuReduser'
import { clearOrdersAction } from '../../store/basketReduser'


function BasketWindow(){
    let timerId2 = null
    const basketWindowRef = useRef(null)
    //redux
    const dispatch = useDispatch()
    //states
    const [loadingActive, setLoadingActive] = useState(false)
    const [backetSum, setBasketSum] = useState(0)
    const [backetSumLimited, setBasketSumLimited] = useState(0)
    const [intervalId, setIntervalId] = useState()
    //selectors
    const basket = useSelector(state => state.menu.basket)
    const orders = useSelector(state => state.menu.orders)

    //consts
    const groupedBasket = basket.reduce((acc, curr) => {
        const foundItem = acc.find(item => item.product_id === curr.product_id);
        if (foundItem) {
          foundItem.count += 1;
        } else {
          acc.push({ ...curr, count: 1 });
        }
        return acc;
      }, []);

    //states
    const [askModal, setAskModal] = useState(null)
    const [animationQueue, setAnimationQueue] = useState(0)

    //functions
    const openMain = () =>{
        window.location.href = '/'
    }

    const update_orders = async() => {
        await set_orders(dispatch)
    }

    const runSetOrders = async () => {
        try {
            await update_orders();
        } catch (error) {
            console.log(error);
        }
    };

    async function createNewPayOrder(order){
        setBasketSum(calculate_basket_sum(basket))
        //викликається модальне вікно для користувача, та очікується на його вибір
        const type = await new Promise((resolve, reject) => {
            const choices = [
                {
                    title: 'Картка',
                    value: 'card',
                    active: true,
                    shord_description: ''
                },
                {
                    title: 'Крипта',
                    value: 'cripto',
                    active: backetSum >= (200*100) ? true : false,
                    shord_description: 'мін 200 грн'
                }
            ]
            setAskModal(<ModalWindow vars={{type:'ask', title:'Виберіть метод оплати:',choices: choices }} funcs={{onChoice:resolve, closeModal:()=>{setAskModal(false)}}}/>)
        });

        setLoadingActive(true)
        clearInterval(intervalId)

        const create_order_result = await create_payment_for_order({
            type:type,
            dispatch: dispatch,
            goods:basket
        }).finally(() => {
            setIntervalId(setInterval(runSetOrders, 2000))
        }) 

        await update_orders()

        setLoadingActive(false)

        if (create_order_result.result){
            await delete_order_from_server(order)

            dispatch(clear_basket_action())
            clearInterval(intervalId)
            dispatch(clearOrdersAction())

            document.location.href = create_order_result.data.link
        }

        if (!create_order_result.result){
            const type = await new Promise((resolve, reject) => {
                const choices = [
                    {
                        title: 'Зрозуміло',
                        value: 'ok',
                        active: true,
                        shord_description: ''
                    }
                ]
                setAskModal(<ModalWindow vars={{type:'ask', title:'Виникла помилка',choices: choices}} funcs={{onChoice:resolve, closeModal:()=>{setAskModal(false)}}}/>)
            });
        }
    }

    async function createNewPosterOrder() {
        setBasketSum(calculate_basket_sum(basket))
        setLoadingActive(true)

        clearInterval(intervalId)

        await create_new_order_in_poster({
            goods: basket
        }).then(() => {
            setIntervalId(setInterval(runSetOrders, 2000))
        })
        
        setLoadingActive(false)
        basketWindowRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const delete_order_from_server = async(order) => {
        setLoadingActive(true)
        
        await delete_order(order)
        await update_orders()

        setLoadingActive(false)
    }

    const stopGettingOrders  = () => {
        clearInterval(intervalId)

        timerId2 = setTimeout(() => setIntervalId(setInterval(runSetOrders, 2000)), 60000)
    }

     //effects
     useEffect(()=>{
        //запуск анімації
        startAnimation({
            type: 'open',
            animationQueueList: [0, 1, 2, 3, 4, 5, 6],
            setAnimationQueue,
          });
        //
        setBasketSum(calculate_basket_sum(basket))
        setBasketSumLimited(calculate_limited_goods_sum(basket))
    },[])

    /*
    Ця функція встановлює інтервал, який кожні 2 секунди запускає асинхронну функцію set_orders() безперервно.
    */
    useEffect(() => {
        setIntervalId(setInterval(runSetOrders, 2000))

        return () => {
            clearInterval(intervalId)
            clearTimeout(timerId2)
        };
      }, []);


    useEffect(()=>{
        setBasketSum(calculate_basket_sum(basket))
    },[basket])

    return(
        <>
        {loadingActive && (<SimpleLoadingWindow/>)}
            <div ref={basketWindowRef} className='window'>
                {orders?.length>0?(
                            <>
                                <WindowTitleComponent vars={{title:'Замовлення'}}/>
                                <OrdersItemsList 
                                    orders={[...orders]} 
                                    refreshOrders={update_orders} 
                                    setSimpleLoading={setLoadingActive}
                                    handlePay={createNewPayOrder}
                                    stopGettingOrders={stopGettingOrders}
                                />
                            </>
                        )
                    :
                        (
                            <></>
                        )}

                <WindowTitleComponent vars={{title:'Кошик'}} />
                <article id='basketWindow' >
                    {askModal!==false&&askModal}
                    
                    {basket.length<=0?
                        (
                            <section className='basketNull'>
                                <p showfrom={animationQueue<4?'bottom':''} elstatus={animationQueue<4?'hide':'show'}>Ваш кошик пустий ;(</p>
                                <button showfrom={animationQueue<5?'bottom':''} elstatus={animationQueue<5?'hide':'show'} onClick={openMain}>На головну</button>
                            </section>
                        )
                        :
                        (
                        <>
                            <div className='window-flex-column' showfrom={animationQueue<6?'bottom':''} elstatus={animationQueue<6?'hide':'show'}>
                                {groupedBasket.map((good,idx) => <GoodItemList key={idx} viewType='basket' good={good}/>)}
                                <h1 className='basket-sum'>Cума: {(backetSum-backetSumLimited)/100} UAH</h1>

                                { has_limited_goods(basket) && 
                                    <h1 className='basket-sum-limited'>Сума (Товари 18+): {backetSumLimited/100} UAH</h1>
                                }
                                <button onClick={createNewPosterOrder} className='done'>Оформити замовлення</button>
                                
                                { has_limited_goods(basket) && 
                                    <p style={{textAlign:'center'}}>У Вашому кошику є товари, які потребують підтвердження віку. За ці товари Ви зможете розрахуватись лише на барі.</p>
                                }
                            </div>
                        </>
                        )
                    }
                </article>
            </div>
            <CopyrightComponent vars={{color:'black'}}/>
        </>
    )
}

export default BasketWindow