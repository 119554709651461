import './newItem.css'
import { useState } from 'react'

function NewItem(props){
    const {vars} = props
    const {newItem} = vars

    const [newSize, setNewSize] = useState('half')

    return(
        <section className='newItem'>
            <p>{newItem.date}</p>
            <h1>{newItem.title}</h1>
            {newSize === 'half'?
            (
                <>
                    <p dangerouslySetInnerHTML={{ __html: newItem.shortDescription }}></p>
                    <button onClick={() => {setNewSize('full')}} className='buttonLink'>Читати все</button>
                </>
            )
            :
            (
                <>
                    <p dangerouslySetInnerHTML={{ __html: newItem.fullDescription }}></p>
                    <button onClick={() => {setNewSize('half')}} className='buttonLink'>Згорнути все</button>
                </>
            )}
            <img src={newItem.imageUrl} alt='new icon'></img>
        </section>
    )
}

export default NewItem