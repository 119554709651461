import { updateActionsAction } from "../store/actionsReduser";

const getDateFromStr = (dateStr) => {
    let dateObj = new Date(dateStr);
    let year = dateObj.getFullYear();
    let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    let day = ("0" + dateObj.getDate()).slice(-2);
    let formattedDate = year + "." + month + "." + day;
    return formattedDate
}

export async function getActions(dispatch){
    const response = await fetch('/api/info/getActions/')
    const resultJson = await response.json()

    const actionsList = resultJson.map(newItem => {
        return {
            id: newItem.id,
            date: getDateFromStr(newItem.created_at),
            title: newItem.title,
            shortDescription: newItem.short_description,
            fullDescription: newItem.full_description,
            imageUrl:newItem.image,
            type: 'half'
        }
    })
    dispatch(updateActionsAction(actionsList))
}
