import './style.css'

import { useRef, useState, useEffect } from 'react';

function Doters(props){
    //props
    const {viewType, scrollViewElem, itemIdx} = props

    //states
    const [offsetId, setOffsetId] = useState(0);
    const [updateNum, setUpdateNum] = useState(false)
    //useRef
    const scrollChildElem = useRef([]);
    const scrollViewWidth = useRef(null);
    const firstChildElemWidth = useRef(null);

    //funcs
    const handleScroll = () => {
        if (scrollViewElem.current) {
          const scrollOffset = scrollViewElem.current.scrollLeft;
          setOffsetId(Math.round(scrollOffset / firstChildElemWidth.current));
        }
    };

    //useEffects
    useEffect(() => {
        if (scrollViewElem.current) {
          scrollViewWidth.current = scrollViewElem.current.offsetWidth;
          scrollChildElem.current = scrollViewElem.current.children
          const firstChildElem = scrollChildElem.current[0];
          if (firstChildElem) {
            firstChildElemWidth.current = firstChildElem.offsetWidth;
          }
        }
        if (scrollViewElem.current) {
            scrollViewElem.current.addEventListener("scroll", handleScroll);
        }
        setUpdateNum(!updateNum)
        return () => {
            if (scrollViewElem.current) {
                scrollViewElem.current.removeEventListener("scroll", handleScroll);
            }
        };
      }, []);

    return(
        <div className='doters'>
            {scrollChildElem.current.length>1 && (
                <>
                    {viewType === 'scroll' && (
                        <>
                            {Array(scrollChildElem.current.length).fill(0).map((_,idx)=>{
                                return <span key={idx} className={offsetId===idx?'fill':''}></span>
                            })}
                        </>
                    )}
                    {viewType === 'idx' && (
                        <>
                            {Array(scrollChildElem.current.length).fill(0).map((_,idx)=>{
                                return <span key={idx} className={itemIdx===idx?'fill':''}></span>
                            })}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default Doters