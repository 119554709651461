import './aboutWindow.css'
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MatterComponent from '../Matter/matterComponent';

import WindowTitleComponent from '../windowTitleComponent/windowTitleComponent';
import CopyrightComponent from '../copyrightComponent/copyrightComponent';

function AboutWindow(props){
    const {funcs, vars} = props
    const openWindow = useSelector(state => state.window.globalWindowOpen)
    const windowTitle = useSelector(state => state.window.globalWindowData[openWindow].title)
    

    return(
        <>
            <div className='window'>
                <WindowTitleComponent vars={{title:'Про нас'}} />
                <article id='aboutWindow' className=''>
                    <section>
                        <p className='f-0'>
                            Інформатика - це сучасна форма кав'ярень, які поєднюють у собі не лише чудову каву, а й мистецькі виставки, культурні події та виступи музикантів. Це місце, де люди зможуть насолоджуватися вишуканою атмосферою, відкривати нових художників, спілкуватися з цікавими людьми та насолоджуватися кавовими напоями.
                        </p>
                    </section>
                    <MatterComponent/>
                </article>
            </div>
            <CopyrightComponent  vars={{color:'black'}}/>
        </>
    )
}

export default AboutWindow