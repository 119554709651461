import {createStore, combineReducers} from 'redux'
import { windowReduser } from './windowReduser'
import { newsReducer } from './newsReduser'
import { menuReducer } from './menuReduser'
import { basketReducer } from './basketReduser'
import { actionsReducer } from './actionsReduser'
import { musicsReducer } from './musicReduser'
import { composeWithDevTools } from 'redux-devtools-extension';
import { galaryReducer } from './galarysReduser'

const rootReducer = combineReducers({
    window: windowReduser,
    news: newsReducer,
    menu: menuReducer,
    basket: basketReducer,
    actions: actionsReducer,
    musics: musicsReducer,
    galary: galaryReducer,
  }, null)

export const globalStore = createStore(rootReducer, composeWithDevTools())