import './mainWindow.css'
import FooterMenu from '../footerMenu/footerMenu'
import NewGalary from '../newGalary/newGalary'
import NewGalaryItem from '../newGalaryItem/newGalaryItem'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
function MainWindow(props){
    const topNews = useSelector(state => state.news.topNews)
    
    async function openMenu(category=0){
        await startAnimation('close')
        await new Promise((resolve) => setTimeout(resolve, animationQueueList.length*70)); // Затримка на 50мс
        if(category===0)window.location.href = '/menu/?category=0'
        if(category===1)window.location.href = '/menu/?category=1'
        if(category===2)window.location.href = '/menu/?category=2'
        if(category===4)window.location.href = '/actions/'
        if(category===5)window.location.href = '/galary/'
      }

    //анімація
    const animationQueueList = [0,1,2,3,4,5,6]
    const [animationQueue, setAnimationQueue] = useState(0)
    async function startAnimation(type){
        switch (type) {
            case 'open':
                for (let i = 0; i < animationQueueList.length; i++) {
                    const item = animationQueueList[i];
                    await new Promise((resolve) => setTimeout(resolve, 50)); // Затримка на 50мс
                    setAnimationQueue(item);
                }
                break;
            case 'close':
                for (let i = animationQueueList.length - 1; i >= 0; i--) {
                    const item = animationQueueList[i];
                    await new Promise((resolve) => setTimeout(resolve, 50)); // Затримка на 50мс
                    setAnimationQueue(item);
                }
                break;
        
            default:
                break;
        }
    }

    useEffect(()=>{
        //запуск анімації
        startAnimation('open')
    },[])
    

    return(
        <article id='mainWindow'>
            <div showfrom={animationQueue<1?'top':''} elstatus={animationQueue<1?'hide':'show'}>
                <NewGalary>
                    {topNews.map((newItem,idx) => {
                        return(
                            <NewGalaryItem key={idx} imageUrl={newItem.imageUrl} description={newItem.shortDescription} />
                        )
                    })}
                </NewGalary>
            </div>
            <section className='mainSection'>
                <button onClick={()=>{openMenu(0)}} className='f-2' showfrom={animationQueue<1?'bottom':''} elstatus={animationQueue<1?'hide':'show'} >
                    <p>Їжа</p><i style={{color:'black'}} className="material-icons f-2">arrow_forward</i>
                </button>
                <button onClick={()=>{openMenu(2)}} className='f-2'  showfrom={animationQueue<2?'bottom':''} elstatus={animationQueue<2?'hide':'show'}>
                    <p>Напої</p><i style={{color:'black'}} className="material-icons f-2">arrow_forward</i>
                </button>
                <button onClick={()=>{openMenu(1)}} className='f-2'  showfrom={animationQueue<3?'bottom':''} elstatus={animationQueue<3?'hide':'show'}>
                    <p>Товари</p><i style={{color:'black'}} className="material-icons f-2">arrow_forward</i>
                </button>
                <button onClick={()=>{openMenu(4)}} className='f-2'  showfrom={animationQueue<4?'bottom':''} elstatus={animationQueue<4?'hide':'show'}>
                    <p>Події</p><i style={{color:'black'}} className="material-icons f-2">arrow_forward</i>
                </button>
                <button onClick={()=>{openMenu(5)}} className='f-2'  showfrom={animationQueue<5?'bottom':''} elstatus={animationQueue<5?'hide':'show'}>
                    <p>Галарея</p><i style={{color:'black'}} className="material-icons f-2">arrow_forward</i>
                </button>
            </section>
            <FooterMenu/>
        </article>
    )
}

export default MainWindow