import './rentShelves.css'
import SelectRentShelves from '../selectRentShelves/selectRentShelves';
import ModalWindow from '../modalWindow/modalWindow';
import PhoneInput from '../phoneInput/phoneInput';

import { sendRent, getRentedData } from '../../servises/rentServises';
import { useEffect, useState } from 'react';

function RentShelves(props){
    const {vars} = props
    const {settedId} = vars

    const [currentWindow, setCurrentWindow] = useState(0)
    const [reservedDates, setReservedDates] = useState([])

    const [selectId, setSelectId] = useState(settedId)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const [phoneMask, setPhoneMask] = useState('')
    const [phoneValidClass, setPhoneValidClass] = useState('')

    async function getDatas(){
        const datas = await getRentedData('shelves', selectId)
        setReservedDates(datas)
    }
    //
    useEffect(()=>{
        if(selectId>0){
            if(currentWindow<=0)changeWindow('next')
            getDatas()
        }
    },[selectId])
    useEffect(()=>{
        if(selectId>0)getDatas()
    },[])

    const [alertModal, setAlertModal] = useState(null)

    const closeModalAndReaload = () => {
        localStorage.setItem('globalWindowOpen',1)
        setAlertModal(null)
        window.location.href = '/'
      }

    async function handleRentSubmit(e){
        e.preventDefault()
        if(phoneValidClass==='false' ){
            setAlertModal(<ModalWindow vars={{type:'alert', title:'Введіть вірний номер телефону!',choices:[{title:'Добре', value:true}] }} funcs={{closeModal:()=>{setAlertModal(null)}}}/>)
            return
        }
        const formData = new FormData();
        formData.append('id', selectId);
        formData.append('name', name);
        formData.append('phone', phoneMask!==''?'+380'+phoneMask:'');
        formData.append('desctiprion', description);

        const sendResult = await sendRent('shelves', formData)

        sendResult?
          setAlertModal(<ModalWindow vars={{type:'alert', title:"Дякуємо! Ми Вам зателефонуємо.",choices:[{title:'Добре', value:true}] }} funcs={{closeModal: closeModalAndReaload}}/>)
          :
          setAlertModal(<ModalWindow vars={{type:'alert', title:'Щось пішло не по плану :(',choices:[{title:'Добре', value:true}] }} funcs={{closeModal:()=>{setAlertModal(null)}}}/>)
    }

    const changeWindow = (type) => {
        switch (type) {
            case 'next':
                setCurrentWindow(currentWindow + 1)
                break;
            case 'back':
                currentWindow>0?setCurrentWindow(currentWindow - 1):setCurrentWindow(currentWindow)
                break;
        
            default:
                setCurrentWindow(currentWindow)
                break;
        }
    }

    return(
        <div className='window window-flex-column'>
            <article id='rentShelves'>
                {currentWindow>0 && <button className='material-icons back-button' onClick={()=>{changeWindow('back')}}>arrow_back</button>}
                
                {alertModal!==null&&alertModal}

                {currentWindow === 0 && 
                (
                    <>
                        <SelectRentShelves funcs={{onSelect:setSelectId}}/>
                    </>
                )}
                {currentWindow === 1 && (
                    <>
                        <h1>Вибрано стелаж №{selectId}</h1>
                        {reservedDates.length>0? (
                            <ul>
                                {reservedDates.map(dates => <li>Резерв з {dates[0]} по {dates[1]}</li>)}
                            </ul>
                        )
                        :
                            <p>Резервів поки немає :) </p>
                        }
                        <form onSubmit={(e)=>{handleRentSubmit(e)}}>
                            <input required placeholder="Ім'я" type="text" value={name} onChange={(event) => setName(event.target.value)} />
                            <PhoneInput funcs={{setPhoneValidClass:setPhoneValidClass, setPhoneMask:setPhoneMask}} vars={{phoneMask:phoneMask}}/>
                            <textarea placeholder='Додайте опис' value={description} onChange={(event) => setDescription(event.target.value)} />
                            <button className='f-0' type='submit'>Залишити заявку</button>
                        </form>
                    </>
                )}
            </article>
        </div>
    )
}

export default RentShelves