const defaultState = {
    //видкрита новина
    newOpen: 0,
    //усі новини
    news:[],
    topNews:[]
}

//новини
const OPEN_NEW = 'OPEN_NEW'
const CLOSE_NEW = 'CLOSE_NEW'
//викачування новин із сервера
const UPDATE_NEWS = 'UPDATE_NEWS'
const UPDATE_TOP_NEWS = 'UPDATE_TOP_NEWS'

export const newsReducer = (state = defaultState, action) => {
    switch (action.type) {
        //дії з новинами на сайті (відкрити/закрити)
        case OPEN_NEW:
            return {...state, newOpen: action.payload}
        case CLOSE_NEW:
            return {...state, newOpen: 0}

        //викачування новин із сервера
        case UPDATE_NEWS:
            return {...state, news: action.payload}
        case UPDATE_TOP_NEWS:
            return {...state, topNews: action.payload}
        default:
            return state
    }
}

//новини
export const openNewAction = (payload) => ({type:OPEN_NEW, payload})
export const closeNewAction = () => ({type:OPEN_NEW, payload:0})
//викачування новин із сервера
export const updateNewsAction = (payload) => ({type:UPDATE_NEWS, payload})
export const updateTopNewsAction = (payload) => ({type:UPDATE_TOP_NEWS, payload})