import { useEffect, useState } from 'react'
import './loading.css'
import imagel from '../../images/loader.gif'

function Loading(props){
    const [prosents, setProsents] = useState(0)

    useEffect(()=>{
        if(prosents<90){
            setTimeout((val = prosents) => {
                setProsents(val + Math.random()*15)
            },200);
        }
    },[prosents])

    useEffect(()=>{
        setProsents(prosents + 1 )
    },[])

    return(
        <article id='loading'>
            <section>
                <img src={imagel} alt="Example GIF"></img>
                <div className='loading-el'>
                    <p style={{left: (prosents-25)+'%'}}>Loading {Math.floor(prosents, 1)}%</p>
                    <div className='loading-bar-bg'></div>
                    <div style={{width:prosents+'%'}} className='loading-line'></div>
                </div>
            </section>

        </article>
    )
}

export default Loading