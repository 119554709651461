import './footerMenu.css'
import CopyrightComponent from '../copyrightComponent/copyrightComponent'

function FooterMenu(props){
    const {funcs, vars} = props
    

    return(
        <footer id='footerMenu'>
            <section>
                <h1 className='f-2'>Де?</h1>
                <p className='f-0'>Київ, Поділ, вул. Костянтинівська 16<br/> (метро Контрактова площа)</p>
            </section>
            <section>
                <h1 className='f-2'>Коли?</h1>
                <p className='f-0'>Пн-Нд: 10:00-22:00 </p>
            </section>
            <section>
                <h1 className='f-2'>Контакти</h1>
                <p className='f-0'>+38(050) 604 4121<br/><a href="mailto:coffee@informatyka.space">coffee@informatyka.space</a></p>
            </section>
            <section>
                <a className='f-0' href='https://t.me/informatyka_channel' target="_blank">Telegram</a>
                <a className='f-0' href='https://instagram.com/informatyka.space?igshid=YmMyMTA2M2Y=' target="_blank">Instagram</a>
            </section>
            <CopyrightComponent vars={{color:'white'}} />
        </footer>
    )
}

export default FooterMenu