import './style.css'
//components
import OrderItem from '../orderItem/orderItem'
import Doters from '../doters/doters';
//react
import { useRef} from 'react'

function OrdersItemsList(props){
    const {orders, refreshOrders, setSimpleLoading, handlePay, stopGettingOrders} = props

    const ElemRef = useRef(null)

    return(
        <>
            <section className='orders-list' ref={ElemRef}>
                {orders.length>0 && (
                    <>
                        {orders.map((order, idx) => (
                            <OrderItem 
                                key={idx} 
                                order={order} 
                                refreshOrders={refreshOrders} 
                                setSimpleLoading={setSimpleLoading}
                                handlePay={handlePay}
                                stopGettingOrders={stopGettingOrders}
                            />
                        ))}
                    </>
                )}
            </section>
            <Doters viewType='scroll' scrollViewElem={ElemRef}/>
        </>
    )
}

export default OrdersItemsList