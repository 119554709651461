import './style.css'
import { useEffect, useState } from 'react'

function GroupModifications(props){
    const {onSelectModification, groupModifications} = props

    //states
    const [modifications, setModifications] = useState([])
    //funcs
    const handleSelectMod = (groupId, modId) => {
        let changeModifications = [...modifications]
        changeModifications[groupId].m = modId
        setModifications(changeModifications)
        
        onSelectModification(changeModifications)
    }
    
    //effects
    useEffect(()=>{
        let startModifications = []
        groupModifications.forEach((group) => {
            startModifications.push({
                m: group.modifications[0].dish_modification_id,
                a: 1
            })
        setModifications(startModifications)   
        onSelectModification(startModifications) 
        
        });
    },[])
    

    return(
        <section className='mod_section'>
            {groupModifications.map((group, idx) => (
                <select key={idx} className="mod_selector" onChange={(e) => handleSelectMod(idx, e.target.value)}>
                    {group.modifications.map((mod, index) => (
                        <option key={index} value={mod.dish_modification_id}>{mod.name}</option>
                    ))}
                </select>
            ))}
        </section>
       
    )
}

export default GroupModifications