import './selectRentShelves.css'

function SelectRentShelves(props){
    const {funcs} = props
    const {onSelect} = funcs

    return(
        <>
            <h1>Виберіть стелаж:</h1>
            <section id='selectRentShelves'>
                <div onClick={()=>{onSelect(1)}}>#1</div>
                <div onClick={()=>{onSelect(2)}}>#2</div>
                <div onClick={()=>{onSelect(3)}}>#3</div>
                <div onClick={()=>{onSelect(4)}}>#4</div>
                <div onClick={()=>{onSelect(5)}}>#5</div>
            </section>
        </>
    )
}

export default SelectRentShelves