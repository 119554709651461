import { useState } from 'react';
import { sendDataForCreateQrCodeAndGetImgLink } from '../../servises/qrCodeServises';
import './qrCodeWindow.css'

function QrCodeWindow(props){
    const [qrImage, setQrImage] = useState('')
    const [qrStrData, setQrStrData] = useState('')
    
    async function handleContactSubmit(e){
        e.preventDefault()
        const formData = new FormData();
        formData.append('qrStrData', qrStrData);
        const sendResult = await sendDataForCreateQrCodeAndGetImgLink(formData)
        setQrImage(sendResult)
    }

    return(

        <div className='window window-flex-column'>
            <article id='qrCodeWindow'>
                <form onSubmit={(e)=>{handleContactSubmit(e)}}>
                    <input className='f-0' required value={qrStrData} onChange={(e)=>{setQrStrData(e.target.value)}} type='text' placeholder="Введіть посиланя"></input>
                    <button className='f-0' type='submit'>Створити QR</button>
                </form>
                {qrImage !== '' && <img src={qrImage} alt='' ></img>}
            </article>
        </div>
    )
}

export default QrCodeWindow