import './style.css'
//images
import imagel from '../../images/loader.gif'


function SimpleLoadingWindow(props){
    

    return(
        <article id='simple-loading'>
            <img src={imagel} alt="Example GIF"></img>
        </article>
    )
}

export default SimpleLoadingWindow