export async function startAnimation({type, animationQueueList, setAnimationQueue}){
    switch (type) {
        case 'open':
            for (let i = 0; i < animationQueueList.length; i++) {
                const item = animationQueueList[i];
                await new Promise((resolve) => setTimeout(resolve, 50)); // Затримка на 50мс
                setAnimationQueue(item);
            }
            break;
        case 'close':
            for (let i = animationQueueList.length - 1; i >= 0; i--) {
                const item = animationQueueList[i];
                await new Promise((resolve) => setTimeout(resolve, 50)); // Затримка на 50мс
                setAnimationQueue(item);
            }
            break;
    
        default:
            break;
    }
}