import './goodItem.css'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
//servises
import { add_good_to_basket, remove_good_from_basket, increase_good_count, reduce_good_count } from '../../servises/menuServises'
//components
import GroupModifications from '../groupModifications/groupModifications'
//viewType - це змінна, яка вказує, де буде використовуватись компонент GoodItem, в кошику або в меню.(str)
//good - це змінна, яка містить інформацію про конкретний товар. (obj)
//scrollToItem - функція, що викликається після нажимання "додати", вона додає товар у кошик і скролить до нього у списку
function GoodItem(props){
    //props
    const {good, viewType, showType, scrollToItem, updateList} = props
    //state
    const [updateNum, setUpdateNum] = useState(false)
    const [currentGood, setCurrentGood] = useState({...good})
    //redux
    const dispatch = useDispatch()
    const basket = useSelector(state => state.menu.basket)
    //funcs
    const openBasket = () => {
        window.location.href = '/basket/'
    }
    const isGoodInBasket = (customGoodId) => {
        return basket.some(item => item.custom_good_id === customGoodId );
    }    
    const handleSelectMod = (id) => {
        const good_price = currentGood.price?.[1] ? parseInt(currentGood.price[1]) : currentGood.cost;
        setCurrentGood({...currentGood,
            selected_modificator_id: id,
            full_price: parseInt(good_price) + parseInt(currentGood.modifications.find(mod => mod.modificator_id === id).modificator_selfprice)
         })
        setUpdateNum(!updateNum)
    }

    const handleAddToBasket = (elementId, newGood, dispatch) => {
        let customGoodId = 0
        if(currentGood.selected_modificator_id){
            customGoodId = currentGood.selected_modificator_id + currentGood.product_id
        }
        else if (currentGood.selected_group_modifications){
            customGoodId = currentGood.product_id

            currentGood.selected_group_modifications.forEach(groupMod => {
                customGoodId = customGoodId + groupMod.m
            });
        }
        else{
            customGoodId = currentGood.product_id
        }

        const updatedGood = {
             ...currentGood,
             custom_good_id: customGoodId,
             count: 1
            };

        if(!isGoodInBasket(customGoodId)){
            add_good_to_basket(updatedGood, dispatch)
            if(updateList)updateList()
        }else{
            if(scrollToItem) scrollToItem(customGoodId)
        }
    }

    const handleChangeGroupModifications = (newModifications) => {
        const good_price = currentGood.price?.[1] ? parseInt(currentGood.price[1]) : currentGood.cost;
        // console.log('new mod');
        // console.log(newModifications);
        const full_price = newModifications.reduce((acc, m) => {
            const groupMod = currentGood.group_modifications.find(modGroup => modGroup.modifications.find(mod => mod.dish_modification_id == parseInt(m.m)));
          
            if (groupMod) {
              const mod = groupMod.modifications.find(mod => mod.dish_modification_id == parseInt(m.m));
              if (mod && mod.price) {
                acc += mod.price * 100;
              }
            }
          
            return acc;
          }, 0);
        // console.log(full_price);

        setCurrentGood({...currentGood,
            selected_group_modifications: newModifications,
            full_price: good_price + parseInt(full_price)
         })
        // console.log(newModifications);
    }

    const handleDeleteFromBasket = (deleteGood) => {
        remove_good_from_basket(deleteGood, dispatch)
        if(updateList)updateList()
    }

    const handleIncrease = (increaseGood) => {
        increase_good_count(increaseGood, dispatch)
    }
    const handleReduce = (reduceGood) => {
        reduce_good_count(reduceGood, dispatch)
    }
    
    //useEffect
    useEffect(()=>{
        const sync = () => {
            if(currentGood.modifications && currentGood.modifications.length > 0){
                handleSelectMod(currentGood.modifications[0].modificator_id)
            }
        }
        sync()
    },[])

    return(
        <section id={`${viewType === 'basket'?good.selected_modificator_id+good.product_id:currentGood.selected_modificator_id}`} className={`${viewType==='menu'?'line':''} line goodItem`}>
            <>
                {viewType === 'menu' && (
                    <>
                        <div className='sec-1'>
                            <div className='description'>
                                <h1 className='f-1'>{currentGood.product_name}</h1>
                                {currentGood.limited && 
                                    <h1 className='f-0'> <span className='limited-mark'>18+</span></h1>
                                }
                                <p className='f-0'>{currentGood.description}</p>
                                {currentGood.out != 0 && 
                                    <p className='f-0'>{currentGood.out} г</p>
                                }
                            </div>
                            <img src={'https://joinposter.com'+currentGood.photo} alt='icon'></img>
                        </div>
                        <div className='sec-2'>
                            <div className='price'>
                                <p className='f-0'>{(currentGood.full_price/100)}</p> <p>UAH</p>
                            </div>
                            {currentGood.modifications && (
                                
                                <>
                                    <select className="mod_selector" value={currentGood.selected_modificator_id} onChange={(e) => handleSelectMod(e.target.value)}>
                                        {currentGood.modifications.map((mod, index) => (
                                            <option key={index} value={mod.modificator_id}>{mod.modificator_name}</option>
                                        ))}
                                    </select>
                                </>
                            )}
                            {currentGood.group_modifications && (
                                <GroupModifications onSelectModification={handleChangeGroupModifications} groupModifications={currentGood.group_modifications}/>
                            )}
                        </div>
                        <div className='sec-3'>
                            <button style={{color:'white'}} onClick={() => handleAddToBasket(currentGood.selected_modificator_id+currentGood.product_id, currentGood, dispatch)} className='add f-0'>Додати</button>
                            
                        </div>
                    </>
                )}
                {viewType === 'basket' && (
                    <>
                        <div className='sec-1'>
                            <div className='description'>
                                <h1 className='f-1'>{good.product_name}</h1>

                                {currentGood.modifications && (
                                    <h2 className='f-0'>{good.modifications.find(item => item.modificator_id === good.selected_modificator_id).modificator_name}</h2>
                                )}
                                {currentGood.group_modifications && (
                                    <>
                                        {currentGood.group_modifications.map((group, idx) => (
                                            <h2 key={idx} className='f-0'>{group.modifications.find(item => item.dish_modification_id == good.selected_group_modifications[idx].m).name}</h2>
                                        ))}
                                    </>
                                )}

                                {good.limited && 
                                    <h1 className='f-0'> <span className='limited-mark'>18+</span></h1>
                                }
                                <p className='f-0'>{currentGood.description}</p>
                                {currentGood.out != 0 && 
                                    <p className='f-0'>{currentGood.out} г</p>
                                }
                            </div>
                            <img src={'https://joinposter.com'+currentGood.photo} alt='icon'></img>
                        </div>
                        <div className='sec-2'>
                            <div className='price'>
                                <p className='f-0'>{((good.full_price*good.count)/100)}</p> <p>UAH</p>
                            </div>
                            {showType === 'menu' && (
                                <button  style={{color:'black'}} onClick={()=>{openBasket()}} className='delete f-0'>Перейти до кошика</button>
                            )}
                            {showType === 'basket' && (
                                <button  style={{color:'black'}} onClick={()=>{handleDeleteFromBasket(good)}} className='delete f-0'>Видалити</button>
                            )}
                        </div>
                        <div className='sec-3'>
                            <div>
                                <button style={{color:'black'}} className='f-0' onClick={()=>{handleReduce(good)}}>-</button>
                                <p className='f-0'>{good.count}</p>
                                <button style={{color:'black'}} className='f-0' onClick={()=>{handleIncrease(good)}}>+</button>
                            </div>
                        </div>
                    </>
                )}

            </>
        </section>
    )
}

export default GoodItem