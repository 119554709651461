import './matterComponent.css'
import React, { useRef, useEffect, Children } from 'react';
import Matter from 'matter-js';

const MatterComponent = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const engine = Matter.Engine.create();
    const world = engine.world;
    const canvas = canvasRef.current;

    const render = Matter.Render.create({
      canvas: canvas,
      engine: engine,
      options: {
        // Встановити фоновий колір з прозорістю
        background: 'transparent',
        wireframes: false // Вимкнути дротяні моделі
      }
    });
    // Додати стилі на кубік
    const boxStyle = {
        fillStyle: 'black' // Встановити колір на чорний
    };
    const createBox = () => {
        const x = (Math.floor(Math.random() * 21) - 10)*10; // генеруємо випадкове число в діапазоні від 0 до 800
        const choise = Math.random()*100;
        if(choise<80 && choise>50){
            return Matter.Bodies.rectangle(400+x, -200, 60, 60, {
                render: boxStyle
            })
        }
        else if (choise<50 && choise>30){
            return Matter.Bodies.polygon(400, 20, 3, 80, {
                render: {
                    fillStyle: 'black'
                },
                // Встановити координати вершин трикутника
                vertices: [
                    { x: 0, y: 0 },
                    { x: 100, y: 0 },
                    { x: 50, y: 50}
                ]
                })
        }
        else{
            return Matter.Bodies.circle(400, -200, 30, {
                render: {
                    fillStyle: 'black',
                    lineWidth: 2
                }
            })
        }
    };
    for (let i = 0; i < 10; i++) {
        setTimeout(() => {
          Matter.World.add(world, [createBox()]);
        }, i * 200); // затримка у мілісекундах залежить від того, як швидко ви хочете додати нову коробку
      }
    const triangle = Matter.Bodies.polygon(400, 20, 3, 80, {
    render: {
        fillStyle: 'black'
    },
    // Встановити координати вершин трикутника
    vertices: [
        { x: 0, y: 0 },
        { x: 100, y: 0 },
        { x: 50, y: 50}
    ]
    });

    // setInterval(() => {
    //     Matter.World.add(world, [box, triangle]);
    // }, 2000);
    const ground = Matter.Bodies.rectangle(400, 605, 910, 8, { isStatic: true });
    const ground1 = Matter.Bodies.rectangle(window.innerWidth+500+window.innerWidth, 605, 10, 900, { isStatic: true });
    const ground2 = Matter.Bodies.rectangle(window.innerWidth+500, 605, 10, 900, { isStatic: true });
    Matter.World.add(world, [ground, ground1, ground2]);

    Matter.Engine.run(engine);
    Matter.Render.run(render);

    return () => {
      Matter.Render.stop(render);
      Matter.Engine.clear(engine);
    };
  }, []);

  return (
    <canvas className='about-matter' ref={canvasRef}/>
  );
};

export default MatterComponent;