
const defaultState = {
    basketGoods:[],
    basketPrice:0,
    //список замовлень
    orders:[],
    currentBasketPrice: 0
}

//корзина
const ADD_GOOD_TO_BASKET = 'ADD_GOOC_TO_BASKET'
const DELETE_GOOD_FROM_BASKET = 'DELETE_GOOD_FROM_BASKET'
const SET_BASKET_FROM_LOCAL_STORE = 'SET_BASKET_FROM_LOCAL_STORE'
const CLEAR_BASKET = 'CLEAR_BASKET'

//товари
const REDUCE_GOOD_COUNT = 'REDUCE_GOOD_COUNT'
const INCREASE_GOOD_COUNT = 'INCREASE_GOOD_COUNT'

//замовлення
const UPDATE_ORDERS = 'UPDATE_ORDERS'
const ADD_ORDER = 'ADD_ORDER'
const DELETE_ORDER = 'DELETE_ORDER'
const SET_ORDERS_FROM_LOCAL_STORE = 'UPDATE_ORDERS_FROM_LOCAL'
const CLEAR_ORDERS = 'CLEAR_ORDERS'

export const basketReducer = (state = defaultState, action) => {
    switch (action.type) {
        // Код додає новий товар до кошика, зберігає його в локальному сховищі та повертає оновлений стан.
        case ADD_GOOD_TO_BASKET:
            localStorage.setItem('basket', JSON.stringify(action.payload))
            return {...state, basketGoods:[...state.basketGoods, action.payload]}
        // Цей код видаляє товар з кошика і оновлює його у стані та localStorage. 
        // Спочатку фільтруємо масив товарів, щоб вилучити той, який потрібно видалити, 
        // потім оновлюємо стан і зберігаємо його у localStorage.
        case DELETE_GOOD_FROM_BASKET:
            const updatedBasketGoods_delete = state.basketGoods.filter((item) => {
              return !Object.keys(action.payload).every((key) => {
                return item[key] === action.payload[key];
              });
            });
            localStorage.setItem('basket', JSON.stringify(updatedBasketGoods_delete));
            return {...state, basketGoods: updatedBasketGoods_delete};

        // Обробник зменшення кількості товару в кошику. Міняє кількість товару у відповідному 
        // елементі кошика, зберігає зміни в локальному сховищі та повертає новий 
        // стан зі зміненим списком товарів в кошику.
        case REDUCE_GOOD_COUNT:
            const updatedBasketGoods_reduce = state.basketGoods.map((basketGood) => {
              if (
                basketGood.category === action.payload.category &&
                basketGood.subCategory === action.payload.subCategory &&
                basketGood.goodId === action.payload.goodId
              ) {
                return {
                  ...basketGood,
                  count: basketGood.count - 1,
                };
              } else {
                return basketGood;
              }
            });
            localStorage.setItem('basket', JSON.stringify(updatedBasketGoods_reduce));
            return {
              ...state,
              basketGoods: updatedBasketGoods_reduce,
            };

        // Обробник збільшення кількості товару в кошику. Міняє кількість товару у відповідному 
        // елементі кошика, зберігає зміни в локальному сховищі та повертає новий 
        // стан зі зміненим списком товарів в кошику.
        case INCREASE_GOOD_COUNT:
            const updatedBasketGoods_increase = state.basketGoods.map((basketGood) => {
              if (
                basketGood.category === action.payload.category &&
                basketGood.subCategory === action.payload.subCategory &&
                basketGood.goodId === action.payload.goodId
              ) {
                return {
                  ...basketGood,
                  count: basketGood.count + 1,
                };
              } else {
                return basketGood;
              }
            });
            localStorage.setItem('basket', JSON.stringify(updatedBasketGoods_increase));
            return {
              ...state,
              basketGoods: updatedBasketGoods_increase,
            };
        // загружає товари в корзину із локального сторе
        case SET_BASKET_FROM_LOCAL_STORE:
            return {...state, basketGoods:JSON.parse(localStorage.getItem('basket')) || []}
        // очищає коризину, використовується при оформленні замовлення
        case CLEAR_BASKET:
            return {...state, basketGoods:[]}
        // використовується для того, щоб розпасити статуси ордерів з сервера та поміняти їх
        case UPDATE_ORDERS:
          localStorage.setItem('orders', JSON.stringify(action.payload));
          return { ...state, orders: action.payload};
        // заружає замовлення користувача з локального сторе
        case SET_ORDERS_FROM_LOCAL_STORE:
            const localStorageOrders = JSON.parse(localStorage.getItem('orders')) || [];
            return { ...state, orders: localStorageOrders };
        // додає новий ордер, використ коли створюється замовлення
        case ADD_ORDER:
          const newOrders = [...state.orders, action.payload];
          localStorage.setItem('orders', JSON.stringify(newOrders));
          return { ...state, orders: newOrders };
        // видаляє ордер, використовується коли 
        case DELETE_ORDER:
          const updatedOrders = state.orders.filter((item) => {
              return !Object.keys(action.payload).every((key) => {
                  return item[key] === action.payload[key];
              });
          });
          localStorage.setItem('orders', JSON.stringify(updatedOrders));
          window.location.href = '/'
          return { ...state, orders: updatedOrders };
        
        case CLEAR_ORDERS:
          const clearOrders = [];
          localStorage.setItem('orders', JSON.stringify(clearOrders));
          return {...state, orders:[]}

        default:
            return state
    }
}
//коризина
export const addGoodToBasketAction = (payload)              => ({type:ADD_GOOD_TO_BASKET, payload})
export const deleteGoodFromBasketAction = (payload)         => ({type:DELETE_GOOD_FROM_BASKET, payload})
export const setBasketFromLocalStoreAction = (payload)      => ({type:SET_BASKET_FROM_LOCAL_STORE, payload})
export const clearBasketAction = (payload)                  => ({type:CLEAR_BASKET, payload})
//меню
export const reduceGoodCountAction = (payload)              => ({type:REDUCE_GOOD_COUNT, payload})
export const increaceGoodCountAction = (payload)            => ({type:INCREASE_GOOD_COUNT, payload})
//замовлення
export const addOrderAction = (payload)                     => ({type:ADD_ORDER, payload})
export const deleteOrderAction = (payload)                  => ({type:DELETE_ORDER, payload})
export const setOrdersFromLocalStoreAction = (payload)      => ({type:SET_ORDERS_FROM_LOCAL_STORE, payload})
export const updateOrdersAction = (payload)                 => ({type:UPDATE_ORDERS, payload})
export const clearOrdersAction = (payload)                  => ({type:CLEAR_ORDERS, payload})