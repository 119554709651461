
const split_content_by_text_and_data = (content_list) => {
    const updated_list = content_list.map((item) => {
        const splited = item.split(':');
        
        return {
            type: splited[0].replace(' ', ''),
            data: splited[1].trim()
        };
    });
    
    console.log(updated_list);
    return updated_list;
};

export const generateContent = (description) => {
    const components = split_content_by_text_and_data(description.split(';').filter((item) => item.trim() !== ''))
    components.map((component) => {
        if (component.type === 'list'){
            component.data = component.data.replace(' ','').split(',');
        }
    })
    return components;
}
  
  
  
  
  

  
  