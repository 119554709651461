
// загружає дані про новий контакт в базу даних
export async function sendContact(formData){
    try {
        const response = await fetch('/api/support/contackMe/', {
          method: 'POST',
          body: formData,
        });
    
        if (response.ok) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
}