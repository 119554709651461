import { useEffect, useState } from 'react'
import './navigationMenu.css'

function NavigationMenu(props){
    const {funcs} = props
    const {closeNavMenu} = funcs

    async function openGlobalWindow(action){
        startAnimation('close')
        await new Promise((resolve) => setTimeout(resolve, animationQueueList.length*70)); // Затримка на 50мс
        if(action===1)window.location.href = '/'
        if(action===2)window.location.href = '/menu/?category=0'
        if(action===3)window.location.href = '/news/'
        if(action===4)window.location.href = '/about/'
        if(action===5)window.location.href = '/contact/'
        // else dispatch(openGlobalWindowAction(action))
        closeNavMenu()
    }
    async function clickCloseMenu(val){
        startAnimation('close')
        await new Promise((resolve) => setTimeout(resolve, animationQueueList.length*70)); // Затримка на 50мс
        closeNavMenu(val)
    }


    //анімація
    const animationQueueList = [0,1,2,3,4,5,6]
    const [animationQueue, setAnimationQueue] = useState(0)
    async function startAnimation(type){
        switch (type) {
            case 'open':
                for (let i = 0; i < animationQueueList.length; i++) {
                    const item = animationQueueList[i];
                    await new Promise((resolve) => setTimeout(resolve, 50)); // Затримка на 50мс
                    setAnimationQueue(item);
                }
                break;
            case 'close':
                for (let i = animationQueueList.length - 1; i >= 0; i--) {
                    const item = animationQueueList[i];
                    await new Promise((resolve) => setTimeout(resolve, 50)); // Затримка на 50мс
                    setAnimationQueue(item);
                }
                break;
        
            default:
                break;
        }
    }

    useEffect(()=>{
        //запуск анімації
        startAnimation('open')
    },[])
    

    return(
        <article showfrom={animationQueue<1?'center':''} elstatus={animationQueue<1?'hide':'show'}  id='navigationMenu'>
            <button showfrom={animationQueue<1?'bottom':''} elstatus={animationQueue<1?'hide':'show'} onClick={()=>{openGlobalWindow(1)}}>Головна</button>
            <button showfrom={animationQueue<2?'bottom':''} elstatus={animationQueue<2?'hide':'show'} onClick={()=>{openGlobalWindow(2)}}>Меню</button>
            <button showfrom={animationQueue<3?'bottom':''} elstatus={animationQueue<3?'hide':'show'} onClick={()=>{openGlobalWindow(3)}}>Новини</button>
            <button showfrom={animationQueue<4?'bottom':''} elstatus={animationQueue<4?'hide':'show'} onClick={()=>{openGlobalWindow(4)}}>Про нас</button>
            <button showfrom={animationQueue<5?'bottom':''} elstatus={animationQueue<5?'hide':'show'} onClick={()=>{openGlobalWindow(5)}}>Контакти</button>
            <section showfrom={animationQueue<6?'bottom':''} elstatus={animationQueue<6?'hide':'show'} className='closeButton'>
                <button onClick={()=>{clickCloseMenu(false)}}>
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="3.75" y="35" width="5.3033" height="44.1942" transform="rotate(-135 3.75 35)" fill="black"/>
                        <rect y="3.75" width="5.3033" height="44.1942" transform="rotate(-45 0 3.75)" fill="black"/>
                    </svg>
                </button>
            </section>
        </article>
    )
}

export default NavigationMenu