import { set_menu_action, set_categories_way_action, add_basket_item_action, remove_basket_item_action, increase_basket_item_action, reduce_basket_item_action } from "../store/menuReduser"

export const get_menu = async(dispatch) => {
    const url = '/api/menu/getMenu/'
    const response = await fetch(url)
    const jsonResponse = await response.json()
    
    const menu = jsonResponse.data
    

    dispatch(set_menu_action(menu))
    dispatch(set_categories_way_action(generate_select_categories_way(menu)))
}
export const update_menu = (menu, dispatch) =>{
    dispatch(set_menu_action(menu))
    dispatch(set_categories_way_action(generate_select_categories_way(menu)))
}
export const generate_select_categories_way = (menu) => {
    let way = [menu]
    let last_point = undefined
    let lastObj = [menu][0]
    while (lastObj) {
        let selected = false
        lastObj.forEach(category => {
            if(!selected){
                if (category.is_select){
                    way.push(category.categories)
                    if (category.categories.length>0){
                        lastObj = category.categories
                        selected = true
                    }else{
                        last_point = category.goods
                        lastObj = undefined
                    }
                }else{
                    lastObj = undefined
                }
            }
        })
    }

    way.forEach(element => {
        element.sort((a, b) => {
            return a.order_id - b.order_id;
          });
    });

    return {way:way, last_point:last_point}
}
export const has_limited_goods = (goods) => {
    for (let i = 0; i < goods.length; i++) {
      if (goods[i].limited === true) {
        return true;
      }
    }
    return false;
}
export const calculate_basket_sum = (goods) => {
    let total = 0;
    for (let i = 0; i < goods.length; i++) {
      if (goods[i].full_price) {
        total += parseInt(goods[i].full_price)*goods[i].count;
      } else {
        total += 0;
      }
    }
    return total;
}
export const calculate_limited_goods_sum = (goods) => {
    let total = 0;
    for (let i = 0; i < goods.length; i++) {
      if (goods[i].full_price) {
        if (goods[i].limited === true) {
            total += parseInt(goods[i].full_price)*goods[i].count;
        }
      } else {
        total += 0;
      }
    }
    return total;
}
export const add_good_to_basket = (newGood, dispatch) => {
    dispatch(add_basket_item_action(newGood))
}
export const remove_good_from_basket = (removeGood, dispatch) => {
    dispatch(remove_basket_item_action(removeGood))
}
export const increase_good_count = (increaseGood, dispatch) => {
    dispatch(increase_basket_item_action(increaseGood))
}
export const reduce_good_count = (reduceGood, dispatch) => {
    dispatch(reduce_basket_item_action(reduceGood))
}
export const update_menu_on_server = async() => {
    const url = '/api/menu/updateMenu/'
    const response = await fetch(url)
    const jsonResponse = await response.json()
    
    if(jsonResponse.result){
        return true
    }
    else{
        console.log(jsonResponse.info);
        return false
    }
    
}
