
const defaultState = {
    //усі новини
    musics:[]
}

//викачування подій із сервера
const UPDATE_MUSICS = 'UPDATE_MUSICS'

export const musicsReducer = (state = defaultState, action) => {
    switch (action.type) {
        //викачування подій із сервера
        case UPDATE_MUSICS:
            return {...state, musics: action.payload}
        default:
            return state
    }
}

//викачування подій із сервера
export const updateMusicsAction = (payload) => ({type:UPDATE_MUSICS, payload})