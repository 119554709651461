import './newGalaryItem.css'

function NewGalaryItem(props){

    const{imageUrl, description} = props

    return(
        <section className='galary-item'>
            <div className='galary-image'>
                <img src={imageUrl} alt=''></img>
            </div>
            <p>{description !== '' ? description : ''}</p>
        </section>
    )
}

export default NewGalaryItem