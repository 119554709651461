import './phoneInput.css'

function PhoneInput(props){
    const {funcs, vars} = props
    
    const {phoneMask} = vars
    const {setPhoneValidClass, setPhoneMask} = funcs

    const codes = ['39','50','63','66','67','68','91','92','93','94','95','96','97','98','99']

    const changePhone = (num) => {
        let mask = num.replace(/[^+\d]/g, '')
        // mask = mask.replace('', '')
        mask = (mask[0]+mask[1])==='38'?mask.replace('38', ''):mask
        mask = mask.split('').map((char,idx)=>{
            switch (idx) {
                case 0:
                    return '('+char
                case 3:
                    return ')'+char
                case 6:
                    return '-'+char
                case 8:
                    return '-'+char
            
                default:
                    return char
            }
        })
  
        mask = mask.join('')
        console.log( codes.indexOf((mask[2]+mask[3])) === -1);
        if(mask==='')setPhoneValidClass('')
        else if(mask[1]!=='0' || codes.indexOf((mask[2]+mask[3])) === -1)setPhoneValidClass('false')
        else if(mask.split('').length<14)setPhoneValidClass('false')
        else setPhoneValidClass('true')
  
        setPhoneMask(mask)
    }

    return(
        <input required className={`f-0 phoneInput`} value={phoneMask} onChange={(e)=>{changePhone(e.target.value)}} type='text' maxLength='14' placeholder='(097) 000-00-00'></input>
    )
}

export default PhoneInput