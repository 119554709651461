import './contackWindow.css'
import mapImage from '../../images/map.jpeg'

import WindowTitleComponent from '../windowTitleComponent/windowTitleComponent'
import CopyrightComponent from '../copyrightComponent/copyrightComponent'
import ModalWindow from '../modalWindow/modalWindow'
import PhoneInput from '../phoneInput/phoneInput'

import { useState } from 'react' 

import { sendContact } from '../../servises/contactServises'

function СontackWindow(props){
    const {funcs, vars} = props

    const [alertModal, setAlertModal] = useState(null)

    const [username, setUsername] = useState('')

    const [phoneMask, setPhoneMask] = useState('')
    const [phoneValidClass, setPhoneValidClass] = useState('')

    async function handleContactSubmit(e){
        e.preventDefault()
        if(phoneValidClass==='false' ){
            setAlertModal(<ModalWindow vars={{type:'alert', title:'Введіть вірний номер телефону!',choices:[{title:'Добре', value:true, active: true, shord_description: ''}] }} funcs={{closeModal:()=>{setAlertModal(null)}}}/>)
            return
        }

        const formData = new FormData();
        formData.append('name', username);
        formData.append('phone', '+380 '+phoneMask);

        const sendResult = await sendContact(formData)

        sendResult?
            setAlertModal(<ModalWindow vars={{type:'alert', title:'Ми Вам зателефонуємо!',choices:[{title:'Добре', value:true, active: true, shord_description: ''}] }} funcs={{closeModal:()=>{setAlertModal(null)}}}/>)
            :
            setAlertModal(<ModalWindow vars={{type:'alert', title:'Щось пішло не по плану :(',choices:[{title:'Добре', value:true, active: true, shord_description: ''}] }} funcs={{closeModal:()=>{setAlertModal(null)}}}/>)
    }

    const open_google_maps = () => {
        window.open(
          "https://www.google.com/maps/place/50°28'01.4%22N+30°30'43.1%22E/@50.4670512,30.5093886,17z/data=!4m4!3m3!8m2!3d50.4670512!4d30.5119635",
          "_blank"
        );
      };

    return(
        <>
            <div className='window'>
                <WindowTitleComponent vars={{title:'Контакти'}} />   
                <article id='contackWindow'>
                    {alertModal!==null&&alertModal}

                    <section>
                        <h1 className='f-2'>Де?</h1>
                        <p>Київ, Поділ, вул. Костянтинівська 16 <br/>(метро Контрактова площа)</p>
                    </section>
                    <section>
                        <h1 className='f-2'>Коли?</h1>
                        <p>Пн-Нд: 10:00-22:00 </p>
                    </section>
                    <section>
                        <h1 className='f-2'>Контакти</h1>
                        <p className='f-0'>+38(050) 604-41-21<br/><a href="mailto:coffee@informatyka.space">coffee@informatyka.space</a></p>
                    </section>
                    <section>
                        <a className='f-0' href='https://t.me/informatyka_channel' target='_blank'>Telegram</a>
                        <a className='f-0' href='https://instagram.com/informatyka.space?igshid=YmMyMTA2M2Y=' target='_blank'>Instagram</a>
                    </section>

                    <img src={mapImage} onClick={()=>{open_google_maps()}} alt='map'></img>
                    <form onSubmit={(e)=>{handleContactSubmit(e)}}>
                        <input className='f-0' required value={username} onChange={(e)=>{setUsername(e.target.value)}} type='text' placeholder="Ваше им'я"></input>
                        <PhoneInput funcs={{setPhoneValidClass:setPhoneValidClass, setPhoneMask:setPhoneMask}} vars={{phoneMask:phoneMask}}/>
                        <button className='f-0' type='submit'>Зв'язатись з нами</button>
                    </form>
                </article>
            </div>
        <CopyrightComponent vars={{color:'black'}}/>
        </>
    )
}

export default СontackWindow