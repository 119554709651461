import { useEffect } from 'react'
import './orderItem.css'
//components
import { delete_order } from '../../servises/orederServises'

function OrderItem(props){
    const {order, refreshOrders, setSimpleLoading, handlePay, stopGettingOrders} = props

    useEffect(() => {
        if (order.status === 'pay') {
            handlePay(order)
        } else if (order.status === 'waiting for delay') {
            stopGettingOrders()
        }
    }, [order.status])

    const open_pay_link_again = () => {
        window.location.href = order.link
    }

    const delete_order_from_server = async() => {
        setSimpleLoading(true)
        
        const deleteResult = await delete_order(order)
        await refreshOrders()

        setSimpleLoading(false)
    }

    return(
        <section className='orderItem'>

            {order.status!=='pay' && order.status!=='prepearing'  && order.status!=='waiting for approve' && order.status !== 'waiting for create poster check' && <p className='orderId f-0'>#{order.id}</p>}
            {order.status==='pay' && (
                <>
                    <div onClick={()=>{delete_order_from_server()}} role='button' className='material-icons deleteOreder'>close</div>
                    <p className='orderStatus f-1'>Очікується оплата...</p>
                    <button onClick={()=>{open_pay_link_again()}} className='f-0'>Спробувати знову</button>
                </>
            )}
            {order.status==='waiting for create poster check' && (<p className='orderStatus f-1'>Оплата успішна, замовлення надсилається...</p>)}
            {order.status==='prepearing' && (<p className='orderStatus f-1'>Оплата успішна, замовлення надсилається...</p>)}
            {(order.status==='waiting for approve' || order.status === 'waiting for delay') && (<p className='orderStatus f-1'>Касир перевіряє Ваше замовлення...</p>)}
            {/* {order.status==='approved' && (<p className='orderStatus f-1'>Ваше замовлення підтверджено, будь ласка, здійсніть оплату :)</p>)} */}
            {order.status==='canceled' && (
                <>
                    <div onClick={()=>{delete_order_from_server()}} role='button' className='material-icons deleteOreder'>close</div>
                    <p className='orderStatus f-0'>Нажаль, Ваше замовлення скасовано :(</p>
                    <p className='orderStatus f-0'>Пройдіть на касу та покажіть ID: #{order.id}</p>
                    {/* <button onClick={()=>{deleteOrder(id)}} className='f-0'>Видалити</button> */}
                </>
            )}
            {order.status==='done' && (
                <>
                    <p className='orderStatus f-1'>Готово! Підійдіть на бар та покажіть ID: #{order.id}</p>
                    <button onClick={()=>{delete_order_from_server()}} className='f-0'>Отримано</button>
                </>
            )}
            <section className='order-info'>
                <p>{order.created_at.split('T')[0]}</p>
                <p>{parseInt(order.sum - order.limited_sum )/100} UAH</p>
            </section>
            
        </section>
    )
}

export default OrderItem