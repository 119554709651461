import './windowTitleComponent.css'
import { useSelector } from 'react-redux';

function WindowTitleComponent(props){
    const {vars} = props

    let title;
    try {({ title} = vars);}
    catch (error) {title = ''}

    const openWindow = useSelector(state => state.window.globalWindowOpen)
    const windowTitle = useSelector(state => state.window.globalWindowData[openWindow].title)

    return(
        <>
            {title!==''? <h1 className='windowTitle f-2'>{title}</h1> : 
            <h1 className='windowTitle f-2'>{windowTitle}</h1>}
        </>
    )
}

export default WindowTitleComponent