import './modalWindow.css'
import { useState, useEffect } from 'react';

function ModalWindow(props) {
    const {funcs, vars} = props
    const {type, title, choices} = vars
    //onChoice - функція яка буде викликатись, після того як користувач зробив вибір
    const {onChoice, closeModal} = funcs

    const [choice, setChoice] = useState(null);

    const handleCloseModal = async() => {
        startAnimation('close')
        await new Promise((resolve) => setTimeout(resolve, animationQueueList.length*70)); // Затримка на 50мс
        closeModal()
    }

    async function handleChoice(value){
        setChoice(value)
        switch (type) {
            case 'ask':
                startAnimation('close')
                await new Promise((resolve) => setTimeout(resolve, animationQueueList.length*70)); // Затримка на 50мс
                onChoice(value)
                closeModal()
                break;
            case 'alert':
                startAnimation('close')
                await new Promise((resolve) => setTimeout(resolve, animationQueueList.length*70)); // Затримка на 50мс
                closeModal()
                break;
        
            default:
                break;
        }
    }

    
    //анімація
    const animationQueueList = [0,1,2,3,4,5,6]
    const [animationQueue, setAnimationQueue] = useState(0)
    async function startAnimation(type){
        switch (type) {
            case 'open':
                setAnimationQueue(0);
                for (let i = 0; i < animationQueueList.length; i++) {
                    const item = animationQueueList[i];
                    await new Promise((resolve) => setTimeout(resolve, 50)); // Затримка на 50мс
                    setAnimationQueue(item);
                }
                break;
            case 'close':
                for (let i = animationQueueList.length - 1; i >= 0; i--) {
                    const item = animationQueueList[i];
                    await new Promise((resolve) => setTimeout(resolve, 50)); // Затримка на 50мс
                    setAnimationQueue(item);
                }
                setAnimationQueue(0);
                break;
        
            default:
                break;
        }
    }

    useEffect(()=>{
        //запуск анімації
        startAnimation('open')
    },[title])

    useEffect(()=>{
        //запуск анімації
        startAnimation('open')
    },[])
    

    return (
        <article id="modalWindow">
            <section showfrom={animationQueue<1?'top':''} elstatus={animationQueue<1?'hide':'show'}>
                <button onClick={()=>{handleCloseModal()}} role='button' className='material-icons closeModal'>close</button>
                <h1 showfrom={animationQueue<2?'top':''} elstatus={animationQueue<2?'hide':'show'}>{title}</h1>
                <div  showfrom={animationQueue<3?'bottom':''} elstatus={animationQueue<3?'hide':'show'}>
                    {choices.map((choice,idx) => (
                            <section key={idx}>
                                <button className={`${!choice.active ? 'disactive' : 'active'}`} onClick={()=>{choice.active ? handleChoice(choice.value) : console.log('недостатня сума');}} >{choice.title}</button>
                                {choice.active ? '' : <p>{choice.shord_description}</p>}
                            </section>
                            )
                        )}
                </div>
            </section>
        </article>
    );
}

export default ModalWindow