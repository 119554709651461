import { useState } from 'react';
import { sendDataForCreateCriptoBillQrCodeImage } from '../../servises/generateBillServises';
import './createCryptoBill.css'

function CreateCryptoBill(props){
    const {funcs, vars} = props

    const [qrImage, setQrImage] = useState('')
    const [qrStrData, setQrStrData] = useState('')
    
    async function handleContactSubmit(e){
        if(parseInt(qrStrData) < 200){
            alert('Введіть суму більше 200 грн')
            return
        }
        e.preventDefault()
        const formData = new FormData();
        formData.append('sum', qrStrData);
        const sendResult = await sendDataForCreateCriptoBillQrCodeImage(formData)
        setQrImage(sendResult)
    }

    return(

        <div className='window window-flex-column'>
            <article id='qrCodeWindow'>
                {qrImage === '' &&
                <form onSubmit={(e)=>{handleContactSubmit(e)}}>
                    <input className='f-0' required value={qrStrData} onChange={(e)=>{setQrStrData(e.target.value)}} type='text' placeholder="Cума (мін 200 грн)"></input>
                    <button className='f-0' type='submit'>Створити рахунок</button>
                </form>
                }
                {qrImage !== '' && 
                    <>
                    <img src={qrImage} alt='qr image link' ></img>
                    <p style={{textAlign:"center"}}>Скануйте QR код, щоб перейти до оплати.</p>
                    </>
                }
            </article>
        </div>
    )
}

export default CreateCryptoBill