import logoImage from '../../images/logoImage.png'
import { useDispatch,useSelector } from 'react-redux'

import './headerMenu.css'


function HeaderMenu(props){
    const {funcs, vars} = props
    const {openNavMenu} = funcs

    const openBasket = () =>{
        window.location.href = '/basket/'
      }
    const openMainWindow = () =>{
        window.location.href = '/'
    }
      
    const basketGoodsCount = useSelector(state => state.menu.basket.length)
    

    return(
        <header id='headerMenu'>
            <img style={{height:'7vh', width:'7vh'}} onClick={openMainWindow} src={logoImage}></img>
            <section className='menuSection'>
                <button className='f-1' onClick={openBasket}>ко шик<div className='notify'>{basketGoodsCount}</div></button>
                <button className='f-1' onClick={()=>{openNavMenu(true)}}>ме ню</button>
            </section>
        </header>
    )
}

export default HeaderMenu